@charset "utf-8";
.p-top {
  &__kv {
    position: relative;
  }
  &__kvImg {
    @include sp {
      width: 100%;
    }
  }
  &__kvBtn {
    position: absolute;
    bottom: 140/768*100vw;
    left: 0;
    right: 0;
    width: 496/768*100vw;
    margin: 0 auto;
    cursor: pointer;
    @include pc {
      bottom: 100px;
      width: 496/2*1px;
      transition: all 0.3s;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  &__kvBtnModal {
    position: relative;
    width: 630/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 630/2*1px;
    }
  }
  &__kvBtnModalImg {
    width: 658/768*100vw;
    @include pc {
      width: 658/2*1px;
    }
  }
  &__kvBtnModalArea {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 650/768*100vw;
    margin: 30/768*100vw auto 0;
    @include pc {
      width: 650/2*1px;
      margin: 30/2*1px auto 0;
    }
  }
  &__kvBtnModalBtn {
    &--01 {
      width: 292/768*100vw;
      @include pc {
        width: 292/2*1px;
      }
    }
    &--02 {
      width: 358/768*100vw;
      @include pc {
        width: 358/2*1px;
      }
    }
  }
  &__kvBtnModalClose {
    position: absolute;
    bottom: -150/768*100vw;
    right: 30/768*100vw;
    width: 56/768*100vw;
    height: 56/768*100vw;
    transform: rotate(45deg);
    transition: all 0s;
    cursor: pointer;
    @include pc {
      bottom: -150/2*1px;
      right: 0/2*1px;
      width: 56/2*1px;
      height: 56/2*1px;
    }
  }
  
  
  &__lead {
    padding: 270/768*100vw 0 140/768*100vw;
    background: url(/assets/img/top/dotted_bg_gray01.webp)#FFF;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 270/2*1px 0 140/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
  }
  &__leadInner {
    width: 650/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 650/2*1px;
    }
  }
  &__leadContent {
    position: relative;
    margin-left: auto;
    padding: 180/768*100vw 30/768*100vw 80/768*100vw;
    background: #C3FFED;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      padding: 180/2*1px 40/2*1px 80/2*1px;
    }
    &--01 {
      width: 567/768*100vw;
      @include pc {
        width: 567/2*1px;
      }
    }
    &--02 {
      width: 100%;
    }
    &:nth-of-type(n+2) {
      margin-top: 250/768*100vw;
      @include pc {
        margin-top: 250/2*1px;
      }
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD, 5/768*100vw 5/768*100vw 0px 2px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        top: 2px;
        right: -2px;
        width: 100%;
        height: 100%;
        box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &__leadContentTtl {
    position: absolute;
    &--01 {
      top: -170/768*100vw;
      left: -75/768*100vw;
      width: 668/768*100vw;
      @include pc {
        top: -170/2*1px;
        left: -75/2*1px;
        width: 668/2*1px;
      }
    }
    &--02 {
      top: -170/768*100vw;
      left: -15/768*100vw;
      width: 477/768*100vw;
      @include pc {
        top: -170/2*1px;
        left: -15/2*1px;
        width: 477/2*1px;
      }
    }
  }
  &__leadContentTxt {
    @include vw(28);
    font-weight: 500;
    line-height: 1.8;
    @include pc {
      font-size: 28/2*1px;
    }
  }
  &__leadContentMovie {
    margin-top: 40/768*100vw;
    @include pc {
      margin-top: 40/2*1px;
    }
    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
  
  
  &__howto {
    padding: 100/768*100vw 0 150/768*100vw;
    background: #FF7F6E;
    overflow: hidden;
    @include pc {
      position: relative;
      padding: 100/2*1px 0 150/2*1px;
      
    }
  }
  &__howtoInner {
    width: 640/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 640/2*1px;
    }
  }
  &__howtoTtl {
    width: 639/768*100vw;
    @include pc {
      width: 639/2*1px;
    }
  }
  &__howtoScroll {
    
  }
  &__howtoSlider {
    overflow-x: scroll;
    margin: 70/768*100vw 50/768*100vw 0;
    padding: 0 0 70/768*100vw;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      margin: 70/2*1px 50/2*1px 0;
      padding: 0 0 70/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #9C58DD;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__howtoSliderInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 4730/768*100vw;
    padding-bottom: 10/768*100vw;
    padding-right: 10/768*100vw;
    @include pc {
      width: 4730/2*1px;
      padding-bottom: 10/2*1px;
      padding-right: 10/2*1px;
    }
  }
  &__howtoSliderContent {
    position: relative;
    width: 570/768*100vw;
    height: 1370/768*100vw;
    background: url(/assets/img/top/howto_step_bg.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin-top: 30/768*100vw;
    padding: 80/768*100vw 40/768*100vw 40/768*100vw 25/768*100vw;
    @include pc {
      width: 570/2*1px;
      height: 1400/2*1px;
      margin-top: 30/2*1px;
      padding: 70/2*1px 40/2*1px 40/2*1px 25/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: -30/768*100vw;
      left: 0;
      right: 0;
      width: 259/768*100vw;
      height: 76/768*100vw;
      margin: 0 auto;
      @include pc {
        top: -30/2*1px;
        width: 259/2*1px;
        height: 76/2*1px;
      }
    }
    &--01 {
      &:before {
        background: url(/assets/img/top/howto_step01.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--02 {
      &:before {
        background: url(/assets/img/top/howto_step02.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--03 {
      &:before {
        background: url(/assets/img/top/howto_step03.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--04 {
      &:before {
        background: url(/assets/img/top/howto_step04.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--05 {
      &:before {
        background: url(/assets/img/top/howto_step05.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--06 {
      &:before {
        background: url(/assets/img/top/howto_step06.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--07 {
      &:before {
        background: url(/assets/img/top/howto_step07.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
    &--08 {
      &:before {
        background: url(/assets/img/top/howto_step08.webp);
        background-size: cover;
        background-repeat: no-repeat;
      }
    }
  }
  &__howtoSliderTtl {
    @include vw(36);
    line-height: 1.0;
    text-align: center;
    @include pc {
      font-size: 36/2*1px;
    }
  }
  &__howtoSliderTxt {
    width: 470/768*100vw;
    @include vw(28);
    line-height: 1.6;
    margin: 80/768*100vw auto 0;
    @include pc {
      width: 470/2*1px;
      font-size: 28/2*1px;
      margin: 80/2*1px auto 0;
    }
    a {
      color: #2BE2AD;
    }
  }
  &__howtoSliderBg {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 40/768*100vw;
    left: -15/768*100vw;
    right: 0;
    width: 506/768*100vw;
    height: 735/768*100vw;
    margin: 0 auto;
    background: url(/assets/img/top/howto_step_displayBg.webp);
    background-size: contain;
    background-repeat: no-repeat;
    @include pc {
      bottom: 40/2*1px;
      left: -15/2*1px;
      right: 0;
      width: 506/2*1px;
      height: 735/2*1px;
    }
    
  }
  &__howtoSliderMovie {
    position: relative;
    width: 330/768*100vw;
    margin-top: 15/768*100vw;
    @include pc {
      width: 330/2*1px;
      margin-top: 15/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 15/768*100vw;
      left: 0;
      right: 0;
      width: 130/768*100vw;
      height: 15/768*100vw;
      margin: 0 auto;
      background: #000;
      border-radius: 0 0 30/768*100vw 30/768*100vw;
      z-index: 1;
      @include pc {
        top: 15/2*1px;
        width: 130/2*1px;
        height: 15/2*1px;
      }
    }
    video {
      width: 100%;
      background: #000;
      border: 16/768*100vw solid #000;
      border-radius: 40/768*100vw;
      @include pc {
        border: 16/2*1px solid #000;
        border-radius: 40/2*1px;
      }
    }
  }
  &__howtoSliderImg {
    width: 326/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 326/2*1px;
    }
  }
  
  &__guide {
    padding: 100/768*100vw 0;
    background: #FFF;
    @include pc {
      padding: 100/2*1px 0;
    }
  }
  &__guideInner {
    width: 660/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 660/2*1px;
    }
  }
  &__guideContentTtl {
    width: 686/768*100vw;
    @include pc {
      width: 686/2*1px;
    }
  }
  &__guideContent {
    position: relative;
    margin-top: 70/768*100vw;
    background: #FF697C;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      margin-top: 70/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF2C47, 5/768*100vw 5/768*100vw 0px 2px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #FF2C47, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &__guideContentSubttl {
    position: relative;
    color: #FFF;
    @include vw(36);
    line-height: 1.5;
    padding: 30/768*100vw 50/768*100vw;
    @include pc {
      font-size: 36/2*1px;
      padding: 30/2*1px 50/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
    }
    &--01 {
      &:before {
        top: -70/768*100vw;
        right: -40/768*100vw;
        width: 256/768*100vw;
        height: 236/768*100vw;
        background: url(/assets/img/top/guide_icon01.webp);
        background-size: contain;
        background-repeat: no-repeat;
        @include pc {
          top: -70/2*1px;
          right: -40/2*1px;
          width: 256/2*1px;
          height: 236/2*1px;
        }
      }
    }
    &--02 {
      &:before {
        top: -80/768*100vw;
        right: -50/768*100vw;
        width: 260/768*100vw;
        height: 262/768*100vw;
        background: url(/assets/img/top/guide_icon02.webp);
        background-size: contain;
        background-repeat: no-repeat;
        @include pc {
          top: -80/2*1px;
          right: -50/2*1px;
          width: 260/2*1px;
          height: 262/2*1px;
        }
      }
    }
  }
  &__guideContentTxt {
    @include vw(28);
    font-weight: 500;
    line-height: 1.8;
    padding: 40/768*100vw 50/768*100vw;
    background: #FFF;
    border-top: 2px solid #000;
    border-radius: 0 0 5px 5px;
    @include pc {
      font-size: 28/2*1px;
      padding: 40/2*1px 50/2*1px;
    }
  }
  
  &__community {
    padding: 100/768*100vw 0 150/768*100vw;
    background: url(/assets/img/top/dotted_bg_gray02.webp)#FF9A08;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    overflow: hidden;
    @include pc {
      padding: 100/2*1px 0 150/2*1px;
      background-size: 34/2*1px 34/2*1px;
//      background: none;
    }
  }
  &__communityInner {
    width: 650/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 650/2*1px;
    }
  }
  &__communitySlide {
    margin: 70/768*100vw 50/768*100vw 0;
    padding-bottom: 50/768*100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      position: relative;
      margin: 70/2*1px 50/2*1px 0;
      padding-bottom: 50/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #FF697C;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__communitySlideInner {
    width: 2100/768*100vw;
    padding-bottom: 10/768*100vw;
    padding-right: 10/768*100vw;
    @include pc {
      width: 2100/2*1px;
      padding-bottom: 10/2*1px;
      padding-right: 10/2*1px;
    }
  }
  &__communitySlideBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:nth-of-type(2) {
      width: 1675/768*100vw;
      margin: 0 auto;
      @include pc {
        width: 1675/2*1px;
      }
    }
    &:nth-of-type(n+2) {
      margin-top: 30/768*100vw;
      @include pc {
        margin-top: 30/2*1px;
      }
    }
  }
  &__communityTtl {
    width: 686/768*100vw;
    @include pc {
      width: 686/2*1px;
    }
  }
  &__communitySlideContent {
    position: relative;
    width: 390/768*100vw;
    height: 235/768*100vw;
    padding: 40/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    @include pc {
      width: 390/2*1px;
      height: 235/2*1px;
      padding: 40/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 10/768*100vw;
      right: 10/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 10/2*1px;
        right: 10/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_purple.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_pink.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.red {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #F64F2A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F64F2A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_red.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A08 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A08 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_orange.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.yellow {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #F9EB20 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F9EB20 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_yellow.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_green.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.none {
      width: 1px;
      height: 235/768*100vw;
      padding: 0;
      border: none;
      background: none;
      @include pc {
        height: 235/2*1px;
      }
    }
  }
  &__communitySlideTtl {
    @include vw(32);
    font-weight: 700;
    line-height: 1.5;
    @include pc {
      font-size: 32/2*1px;
    }
  }
  
  &__faq {
    position: relative;
    padding: 100/768*100vw 0 360/768*100vw;
    background: #FFF;
    @include pc {
      padding: 100/2*1px 0 360/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -5/768*100vw;
      left: 0;
      right: 0;
      width: 583/768*100vw;
      height: 310/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/top/faq_img.webp);
      background-size: contain;
      background-repeat: no-repeat;
      @include pc {
        bottom: -5/2*1px;
        width: 583/2*1px;
        height: 310/2*1px;
      }
    }
  }
  &__faqInner {
    width: 650/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 650/2*1px;
    }
  }
  &__faqContent {
    position: relative;
    margin-top: 70/768*100vw;
    background: #FF697C;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      margin-top: 70/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A06, 5/768*100vw 5/768*100vw 0px 2px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A06, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: -40/768*100vw;
      left: -40/768*100vw;
      width: 103/768*100vw;
      height: 100/768*100vw;
      @include pc {
        top: -40/2*1px;
        left: -40/2*1px;
        width: 103/2*1px;
        height: 100/2*1px;
      }
    }
    &--01 {
      &:after {
        background: url(/assets/img/top/faq_num01.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--02 {
      &:after {
        background: url(/assets/img/top/faq_num02.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--03 {
      &:after {
        background: url(/assets/img/top/faq_num03.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__faqContentSubttl {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 190/768*100vw;
    background: #FFF;
    @include vw(36);
    font-weight: 900;
    line-height: 1.5;
    padding: 0 75/768*100vw;
    @include pc {
      height: 190/2*1px;
      font-size: 36/2*1px;
      padding: 0 75/2*1px;
    }
  }
  &__faqContentTxt {
    @include vw(28);
    font-weight: 500;
    line-height: 1.8;
    padding: 30/768*100vw 30/768*100vw;
    background: #FFDBA5;
    border-top: 2px solid #000;
    border-radius: 0 0 5px 5px;
    @include pc {
      font-size: 28/2*1px;
      padding: 30/2*1px 30/2*1px;
    }
    a {
      text-decoration: underline;
    }
  }
}
.modaal-container {
  width: auto;
  max-width: inherit;
  background: none;
  box-shadow: none;
}
.modaal-content-container {
  margin: 0 auto;
  padding: 0;
}
.modaal-close {
  display: none;
  &.active {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    opacity: 0;
  }
}
.p-top {
  &__communityModal {
    position: relative;
    width: 630/768*100vw;
    margin: 0 auto;
    padding: 60/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    @include pc {
      width: 630/2*1px;
      padding: 60/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &.purple {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #9C58DD, 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #9C58DD, 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
    &.pink {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #FE4E6A , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #FE4E6A , 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
    &.red {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F64F2A , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #F64F2A , 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
    &.orange {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #FF9A08 , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #FF9A08 , 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
    &.yellow {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F9EB20 , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #F9EB20 , 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
    &.green {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #2BE2AD , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 10/2*1px 10/2*1px 0px 0px #2BE2AD , 10/2*1px 10/2*1px 0px 2px #000;
        }
      }
    }
  }
  &__communityModalTtl {
    @include vw(36);
    line-height: 1.3;
    @include pc {
      font-size: 36/2*1px;
    }
  }
  &__communityModalTxt {
    @include vw(28);
    margin-top: 30/768*100vw;
    @include pc {
      font-size: 28/2*1px;
      margin-top: 30/2*1px;
    }
  }
  &__communityModalClose {
    position: absolute;
    bottom: 30/768*100vw;
    right: 30/768*100vw;
    width: 56/768*100vw;
    height: 56/768*100vw;
    transform: rotate(45deg);
    transition: all 0s;
    cursor: pointer;
    @include pc {
      bottom: 30/2*1px;
      right: 30/2*1px;
      width: 56/2*1px;
      height: 56/2*1px;
    }
  }
}