@charset "utf-8";
.l-footer {
  position: relative;
  z-index: 3;
  @include pc {
    position: relative;
    width: 433px;
    margin: 0 auto;
    padding: 0 20px;
    background: #F3F3F3;
  }
  &__wrap {
    position: relative;
    padding: 60/768*100vw 0 20/768*100vw;
    background: #2E2F33;
    @include pc {
      width: 393px;
      margin: 0 auto;
      padding: 60/2*1px 0 20/2*1px;
    }
  }
  &__inner {
    width: 630/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 630/2*1px;
    }
  }
  &__logo {
    width: 248/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 248/2*1px;
    }
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 60/768*100vw auto 0;
    @include pc {
      margin: 60/2*1px auto 0;
    }
    a {
      &.none {
        color: #6D6E71;
        pointer-events: none;
      }
    }
  }
  &__linksItem {
    width: 50%;
    @include vw(22);
    @include pc {
      font-size: 22/2*1px;
    }
    &:nth-of-type(n+3) {
      margin-top: 50/768*100vw;
      @include pc {
        margin-top: 50/2*1px;
      }
    }
    a {
      color: rgba(255,255,255,0.5);
      text-decoration: none;
    }
  }
  &__Copyright {
    color: rgba(255,255,255,0.5); 
    @include vw(20);
    text-align: center;
    margin-top: 80/768*100vw;
    @include pc {
      font-size: 20/2*1px;
      margin-top: 80/2*1px;
    }
  }
}