.p-encounter {
  &__contents {
    position: relative;
    padding: 60/768*100vw 0 390/768*100vw;
    background: url(/assets/img/top/dotted_bg_white.webp)#FF697C;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 60/2*1px 0 390/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -10/768*100vw;
      left: 0;
      right: 0;
      width: 583/768*100vw;
      height: 310/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/encounter/encounter_bottomImg.webp);
      background-repeat: repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        bottom: -10/2*1px;
        width: 583/2*1px;
        height: 310/2*1px;
      }
    }
  }
  &__contentsInner {
    width: 650/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 650/2*1px;
    }
  }
  &__contentsMainttl {
    width: 634/768*100vw;
    @include pc {
      width: 634/2*1px;
    }
  }
  &__contentsBlock {
    margin-top: 80/768*100vw;
    @include pc {
      margin-top: 80/2*1px;
    }
  }
  &__contentsBlockTtl {
    span {
      display: inline-block;
      color: #FFF;
      @include vw(30);
      font-weight: 900;
      line-height: 1.0;
      padding: 15/768*100vw 25/768*100vw;
      background: #000;
      @include pc {
        font-size: 30/2*1px;
        padding: 15/2*1px 25/2*1px;
      }
      &:nth-of-type(n+2) {
        margin-top: 10/768*100vw;
        @include pc {
          margin-top: 10/2*1px;
        }
      }
    }
  }
  &__contentsBlockSubttl {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 640/768*100vw;
    color: #FFF;
    @include vw(34);
    font-weight: 900;
    line-height: 1.5;
    margin-top: 10/768*100vw;
    padding: 20/768*100vw 60/768*100vw;
    background: #FF9A08;
    border: 2px solid #000;
    @include pc {
      width: 640/2*1px;
      font-size: 34/2*1px;
      margin-top: 10/2*1px;
      padding: 20/2*1px 60/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #D07B00, 5/768*100vw 5/768*100vw 0px 5/768*100vw #000;
      @include pc {
        top: 2px;
        right: -2px;
        width: 100%;
        height: 100%;
        box-shadow: 5/2*1px 5/2*1px 0px 0px #D07B00, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &__contentsBlockNotes {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 380/768*100vw;
    height: 60/768*100vw;
    color: #FFF;
    @include vw(30);
    font-weight: 700;
    line-height: 1.0;
    margin-top: 20/768*100vw;
    padding: 0 30/768*100vw;
    background: #979799;
    @include pc {
      width: 640/2*1px;
      height: 60/2*1px;
      font-size: 30/2*1px;
      margin-top: 20/2*1px;
      padding: 0 30/2*1px;
    }
  }
  &__contentsBlockLinks {
    margin-top: 20/768*100vw;
    border: 5/768*100vw solid #000;
    @include pc {
      margin-top: 20/2*1px;
      border: 5/2*1px solid #000;
    }
    a {
      &.none {
        pointer-events: none;
      }
    }
  }
}