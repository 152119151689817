@charset "utf-8";

// .l-header,
// .l-footer{
//   display: none;
// }

.p-topKv {
  width: 100%;
  height: 100vh;
  background-color: #FF9A08;

  &__container {
    position: relative;
    max-width: 413px;
    height: 100%;
    margin: 0 auto;
//    border-left: 10px solid #F3F3F3;
//    border-right: 10px solid #F3F3F3;
    background-color: #fff;

    @media screen and (max-width: 768px) {
      max-width: none;
      border: none;
    }

    // @at-root .iPad & {
    //   max-width: none;
    //   border: none;
    // }

    canvas {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &__logo {
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 40%;
    left: 50%;
    width: 353px;
    opacity: 0;
    z-index: 9;
    filter: drop-shadow(2px 2px 4px rgba(0,0,0,.16));

    @media screen and (max-width: 768px) {
      width: calc(674 / 750 * 100vw);
    }

    @media (orientation: landscape) and (max-width: 768px) {
      max-width: 353px;
    }

    // @at-root .iPad & {
    //   max-width: 353px;
    // }

    &.is-fadeIn {
      animation: fadeInAnime 0.6s 3.0s forwards;
    }
  }
}

@keyframes fadeInAnime {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
