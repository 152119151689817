.p-paid {
  overflow: hidden;
  &__inner {
    position: relative;
    padding: 70/768*100vw 0 380/768*100vw;
    background: url(/assets/img/top/dotted_bg_white.webp)#B57CEB;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 70/2*1px 0 380/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -10/768*100vw;
      left: 0;
      right: 0;
      width: 583/768*100vw;
      height: 310/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/basic/functionMore_bottomImg.webp);
      background-repeat: repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        bottom: -10/2*1px;
        width: 583/2*1px;
        height: 310/2*1px;
      }
    }
  }
  &__planTtl {
    width: 660/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 660/2*1px;
    }
  }
  &__planContent {
    position: relative;
    width: 660/768*100vw;
    margin: 45/768*100vw auto 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1;
    @include pc {
      width: 660/2*1px;
      margin: 45/2*1px auto 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FC2D7B, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FC2D7B, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.yellow {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #E89D33, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #E89D33, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.black {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #606060, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #606060, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
  }
  &__planContentSubttl {
    position: relative;
    padding: 50/768*100vw;
    @include pc {
      padding: 50/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: 15/768*100vw;
      right: 15/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 15/2*1px;
        right: 15/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.pink {
      &:before {
        background: url(/assets/img/common/btn_pink_grad.webp);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    &.yellow {
      &:before {
        background: url(/assets/img/common/btn_yellow_grad.webp);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    &.black {
      &:before {
        background: url(/assets/img/common/btn_black_grad.webp);
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
  }
  &__planContentSubttlImg {
    &--01 {
      width: 395/768*100vw;
      @include pc {
        width: 395/2*1px;
      }
    }
    &--02 {
      width: 444/768*100vw;
      @include pc {
        width: 444/2*1px;
      }
    }
    &--03 {
      width: 511/768*100vw;
      @include pc {
        width: 511/2*1px;
      }
    }
  }
  &__planContentTxt {
    @include vw(30);
    font-weight: 700;
    line-height: 1.8;
    padding: 40/768*100vw 50/768*100vw;
    background: #FFF;
    border-top: 2px solid #000;
    border-radius: 0 0 5px 5px;
    @include pc {
      font-size: 30/2*1px;
      padding: 40/2*1px 50/2*1px;
    }
    &.pink {
      background: #FFDCD5;
    }
    &.yellow {
      background: #F8E2C2;
    }
    &.black {
      background: #EBEBEB;
    }
  }
  
  
  &__moreTtl {
    width: 665/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 665/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  &__moreScroll {
    margin: 40/768*100vw 50/768*100vw 0;
    padding: 0 0 50/768*100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      margin: 50/2*1px 50/2*1px 0;
      padding: 0 0 50/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #FF3854;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__moreScrollInner {
    width: 1610/768*100vw;
    @include pc {
      width: 1610/2*1px;
    }
  }
  &__moreSlide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1600/768*100vw;
    @include pc {
      width: 1600/2*1px;
    }
  }
  &__moreSlideItem {
    position: relative;
    width: 375/768*100vw;
    padding: 30/768*100vw 40/768*100vw 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    @include pc {
      width: 375/2*1px;
      padding: 30/2*1px 40/2*1px 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 10/768*100vw;
      right: 10/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 10/2*1px;
        right: 10/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.blue {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #52A4FF , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #52A4FF , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_blue.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_purple.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_pink.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.yellow {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #E89D33 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #E89D33 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_yellow_grad.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__moreSlideNum {
    @include vw(24);
    font-weight: 900;
    @include pc {
      font-size: 24/2*1px;
    }
    &.blue {
      color: #52A4FF;
    }
    &.purple {
      color: #9C58DD;
    }
    &.pink {
      color: #FE4E6A;
    }
    &.yellow {
      color: #E89D33;
    }
  }
  &__moreSlideTtl {
    min-height: 210/768*100vw;
    margin-top: 10/768*100vw;
    @include pc {
      min-height: 210/2*1px;
      margin-top: 10/2*1px;
    }
    span {
      @include vw(38);
      line-height: 1.8;
      font-weight: 900;
      @include pc {
        font-size: 38/2*1px;
      }
    }
    &.blue {
      display: flex;
      align-items: center;
      span {
        border-bottom: 1px dashed #52A4FF;
      }
    }
    &.purple {
      display: flex;
      align-items: center;
      span {
        border-bottom: 1px dashed #9C58DD;
      }
    }
    &.pink {
      span {
        border-bottom: 1px dashed #FE4E6A;
      }
    }
    &.yellow {
      span {
        border-bottom: 1px dashed #FF9A08;
      }
    }
  }
  &__moreSlideImg {
    width: 232/768*100vw;
    margin: 20/768*100vw auto 0;
    @include pc {
      width: 232/2*1px;
      margin: 20/2*1px auto 0;
    }
  }
  &__moreImg {
    width: 583/768*100vw;
    margin: 80/768*100vw auto -5/768*100vw;
    @include pc {
      width: 583/2*1px;
      margin: 80/2*1px auto -5/2*1px;
    }
  }
  
  &__list {
    margin-top: 100/768*100vw;
    @include pc {
      margin-top: 100/2*1px;
    }
  }
  &__listTtl {
    width: 655/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 655/2*1px;
    }
  }
  &__listscroll {
    margin: 50/768*100vw 60/768*100vw 0;
    padding: 0 0 50/768*100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      margin: 50/2*1px 60/2*1px 0;
      padding: 0 0 50/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #FF3854;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__listscrollInner {
    width: 1660/768*100vw;
    @include pc {
      width: 1660/2*1px;
    }
  }
  &__listTable {
    position: relative;
    width: 1650/768*100vw;
    padding: 50/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
//    overflow: hidden;
    @include pc {
      width: 1650/2*1px;
      padding: 50/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
      z-index: -1;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
    table {
      th {
        border-right: 1px solid #E1E1E1;
        &:last-of-type {
          border-right: 0;
        }
        .ttl {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 450/768*100vw;
          height: 92/768*100vw;
          @include vw(30);
          border: 2px solid #E1E1E1;
          border-radius: 3px;
          @include pc {
            width: 450/2*1px;
            height: 92/2*1px;
            font-size: 30/2*1px;
          }
        }
        .ttlImg {
          width: 270/768*100vw;
          padding: 0 10/768*100vw;
          @include pc {
            width: 270/2*1px;
            padding: 0 10/2*1px;
          }
        }
      }
      td {
        padding: 0 10/768*100vw 0 0;
        border-right: 1px solid #E1E1E1;
        @include pc {
          padding: 0 10/2*1px 0 0;
        }
        &:nth-of-type(n+2) {
          padding: 0 10/768*100vw;
          @include pc {
            padding: 0 10/2*1px;
          }
        }
        &:last-of-type {
          border-right: 0;
        }
        .subttl {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          width: 450/768*100vw;
          min-height: 92/768*100vw;
          @include vw(24);
          font-weight: 700;
          text-align: center;
          padding: 30/768*100vw 0;
          @include pc {
            width: 450/2*1px;
            min-height: 92/2*1px;
            font-size: 24/2*1px;
            padding: 30/2*1px 0;
          }
        }
        .bg {
          background: #FFF4F6;
        }
        .notes {
          display: block;
          @include vw(18);
          font-weight: normal;
          line-height: 1.5;
          margin-top: 5/768*100vw;
          @include pc {
            font-size: 18/2*1px;
            margin-top: 5/2*1px;
          }
        }
        .pink {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 95/768*100vw;
          color: #FD267D;
          font-size: 14px;
          @include pc {
            min-height: 95/2*1px;
              font-size: 14/2*1px;
          }
        }
        .orange {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 95/768*100vw;
          color: #FC6C57;
          font-size: 14px;
          @include pc {
            min-height: 95/2*1px;
            font-size: 14/2*1px;
          }
        }
        .yellow {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 95/768*100vw;
          color: #EEC061;
          font-size: 14px;
          @include pc {
            min-height: 95/2*1px;
            font-size: 14/2*1px;
          }
        }
        .black {
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 95/768*100vw;
          color: #2E2F33;
          font-size: 14px;
          @include pc {
            min-height: 95/2*1px;
            font-size: 14/2*1px;
          }
        }
        .H140 {
          height: 140/768*100vw;
          @include pc {
            height: 140/2*1px;
          }
        } 
        .H170 {
          height: 170/768*100vw;
          @include pc {
            height: 170/2*1px;
          }
        } 
      }
    }
  }
  
  &__modalSubttl {
    &:before {
      position: absolute;
      display: block;
      content: '';
    }
    &--01 {
      &:before {
        top: -70/768*100vw;
        left: -30/768*100vw;
        width: 555/768*100vw;
        height: 243/768*100vw;
        background: url(/assets/img/paid/plan-modal01/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -70/2*1px;
          left: -30/2*1px;
          width: 555/2*1px;
          height: 243/2*1px;
        }
      }
    }
    &--02 {
      &:before {
        top: -90/768*100vw;
        left: -60/768*100vw;
        width: 579/768*100vw;
        height: 110/768*100vw;
        background: url(/assets/img/paid/plan-modal02/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -90/2*1px;
          left: -60/2*1px;
          width: 579/2*1px;
          height: 110/2*1px;
        }
      }
    }
    &--03 {
      &:before {
        top: -60/768*100vw;
        left: -50/768*100vw;
        width: 595/768*100vw;
        height: 274/768*100vw;
        background: url(/assets/img/paid/plan-modal03/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -60/2*1px;
          left: -50/2*1px;
          width: 595/2*1px;
          height: 274/2*1px;
        }
      }
    }
    &--04 {
      &:before {
        top: -60/768*100vw;
        left: -50/768*100vw;
        width: 595/768*100vw;
        height: 274/768*100vw;
        background: url(/assets/img/paid/slide-modal01/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -60/2*1px;
          left: -50/2*1px;
          width: 595/2*1px;
          height: 274/2*1px;
        }
      }
    }
    &--05 {
      &:before {
        top: -130/768*100vw;
        left: -30/768*100vw;
        width: 556/768*100vw;
        height: 378/768*100vw;
        background: url(/assets/img/paid/slide-modal01/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -130/2*1px;
          left: -30/2*1px;
          width: 556/2*1px;
          height: 378/2*1px;
        }
      }
    }
    &--06 {
      &:before {
        top: -70/768*100vw;
        left: -20/768*100vw;
        width: 569/768*100vw;
        height: 352/768*100vw;
        background: url(/assets/img/paid/slide-modal02/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -70/2*1px;
          left: -20/2*1px;
          width: 569/2*1px;
          height: 352/2*1px;
        }
      }
    }
  }

}