@charset "utf-8";
.l-header {
  &__menu {
    position: fixed;
    top: 20/750*100vw;
    right: 20/750*100vw;
    background: #2E2F33;
    border-radius: 50px;
    opacity: 0;
    z-index: 99;
    @include pc {
      display: none;
    }
  }
  &__menuBtn {
    position: relative;
    width: 100/750*100vw;
    height: 100/750*100vw;
  }
  &__menuBtnInner {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40/750*100vw;
    height: 7/750*100vw;
    transform: translate(-50%,-50%);
    span {
      position: absolute;
      width: 1vw;
      height: 1vw;
      background: #FFF;
      border-radius: 50%;
      &:nth-of-type(1) {
        left: 0;
        transition: all 0.3s;
        &.active {
          left: 50%;
          opacity: 0;
          transform: translateX(-50%);
        }
      }
      &:nth-of-type(2) {
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        &.active {
          width: 6vw;
          height: 6vw;
          clip-path: polygon(5% 0, 0 5%, 45% 50%, 0 95%, 5% 100%, 50% 55%, 95% 100%, 100% 95%, 55% 50%, 100% 5%, 95% 0, 50% 45%);
          transition: all 0.3s;
        }
      }
      &:nth-of-type(3) {
        right: 0;
        transition: all 0.3s;
        &.active {
          right: 50%;
          opacity: 0;
          transform: translateX(-50%);
        }
      }
    }
  }
  &__menuList {
//    display: none;
  }
  &__menuListItem {
    text-align: center;
    padding: 30/750*100vw 0;
    border-top: 1px solid #7C7C7C;
    &:nth-of-type(1) a:before {
      background: url(/assets/img/header/menu_icon01.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(2) a:before {
      background: url(/assets/img/header/menu_icon02.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(3) a:before {
      background: url(/assets/img/header/menu_icon03.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(4) a:before {
      background: url(/assets/img/header/menu_icon04.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(5) a:before {
      top: 50%;
      width: 74/750*100vw;
      height: 32/750*100vw;
      background: url(/assets/img/header/menu_icon06.webp);
      background-size: contain;
      background-repeat: no-repeat;
      transform: translateY(-50%);
    }
    &:nth-of-type(5)  {
      padding: 50/750*100vw 0 70/750*100vw;
    }
  }
  &__menuListItem a {
    position: relative;
    display: block;
    color: #FFF;
    font-size: 18/750*100vw;
    line-height: 1.3;
    text-decoration: none;
    padding-top: 40/750*100vw;
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      width: 37/750*100vw;
      height: 37/750*100vw;
      margin: 0 auto;
    }
    &.none {
      color: #7C7C7C;
      pointer-events: none;
    }
  }
} 