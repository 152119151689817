.p-loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgb(253,38,125);
  background: linear-gradient(45deg, rgba(253,38,125,1) 0%, rgba(255,120,84,1) 100%);
  overflow: hidden;
  z-index: 999;
  @include pc {
    background: linear-gradient(90deg, rgba(253,38,125,1) 0%, rgba(255,120,84,1) 100%);
  }
  &.hide {
    opacity: 0;
    pointer-events: none;
    transition: opacity 500ms;
  }
  &__logo {
    width: 131/768*100vw;
    height: 154/768*100vw;
    animation: zoom-inOut 2s infinite;
    @include pc {
      width: 73px;
      height: 86px;
    }
  }
  @keyframes zoom-inOut {
	0% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.3);
	}
  }
}