@charset "utf-8";
.l-main {
  
  &__fixed {
    @include pc {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: url(/assets/img/common/main_bg.webp);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    @include sp {
      display: none;
    }
  }
  &__fixedLeft {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    width: calc(50vw - 226px);
    height: 100vh;
  }
  &__fixedLeftContent {
    width: 268px;
    @include rp {
      width: 268/1200*100vw;
    }
  }
  &__fixedLeftMedia {
    width: 178px;
    margin: 50px auto 0;
    @include rp {
      width: 178/1200*100vw;
      margin: 50/1200*100vw auto 0;
    }
  }
  &__fixedRight {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: calc(50vw + 205px);
    width: calc(50vw - 205px);
    height: 100vh;
  }
  &__fixedRightInner {
    position: absolute;
    left: -1px;
    width: 355px;
    height: 450px;
    padding: 50px 0 50px 60px;
    background: url(/assets/img/common/fixed_right_bg.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    @include rp {
      width: 355/1200*100vw;
      height: 450/1200*100vw;
      padding: 50/1200*100vw 0 50/1200*100vw 60/1200*100vw;
    }
  }
  &__fixedRightLinks {
    position: relative;
    width: 180px;
    z-index: 1;
    @include rp {
      width: 180/1200*100vw;
    }
  }
  &__fixedRightLinksItem {
    border-bottom: 2px solid #F3F3F3;
    a {
      display: block;
      color: #000;
      line-height: 1.0;
      text-decoration: none;
      padding: 15px 0;
      @include rp {
        padding: 15/1200*100vw 0;
      }
      &.none {
        color: #B5B8C3;
        pointer-events: none;
      }
    }
    span {
      position: relative;
      font-size: 14px;
      font-weight: 700;
      white-space: nowrap;
      padding-left: 35px;
      @include rp {
        font-size: 14/1200*100vw;
        padding-left: 35/1200*100vw;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 50%;
        left: 0;
        width: 21px;
        height: 28px;
        transform: translateY(-50%);
        @include rp {
          width: 21/1200*100vw;
          height: 28/1200*100vw;
        }
      }
    }
    &:nth-of-type(1) a span:before {
      background: url(/assets/img/common/fixed_icon01.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(2) a span:before {
      background: url(/assets/img/common/fixed_icon02.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(3) a span:before {
      background: url(/assets/img/common/fixed_icon03.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(4) a span:before {
      background: url(/assets/img/common/fixed_icon04.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-of-type(5) a span:before {
      background: url(/assets/img/common/fixed_icon05.webp);
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__fixedRightSns {
    width: 180px;
    margin-top: 20px;
    @include rp {
      width: 180/1200*100vw;
      margin-top: 20/1200*100vw;
    }
  }
  &__fixedRightSnsTtl {
    width: 110px;
    margin: 0 auto;
    line-height: 1.0;
    @include rp {
      width: 110/1200*100vw;
    }
    img {
      vertical-align: middle;
    }
  }
  &__fixedRightSnsList {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 180px;
    margin: 10px auto 0;
    padding: 10px 20px;
    background: #F3F3F3;
    border-radius: 20px;
    z-index: 1;
    @include rp {
      width: 180/1200*100vw;
      margin: 10/1200*100vw auto 0;
      padding: 10/1200*100vw 20/1200*100vw;
    }
  }
  &__fixedRightSnsListItem {
    width: 20px;
    @include rp {
      width: 20/1200*100vw;
    }
    a {
      display: flex;
    }
  }
  
  
  
  
  &__wrap {
    @include pc {
      position: relative;
//      width: 393px;
      width: 433px;
      margin: 0 auto;
      padding: 0 20px;
      background: #F3F3F3;
    }
  }
}