.p-basic {
  &__kv {
    width: 100%;
  }
  
  &__function {
    padding: 30/768*100vw 0 150/768*100vw;
    background: url(/assets/img/top/dotted_bg_white.webp)#6BE2C0;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 30/2*1px 0 150/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
  }
  &__functionInner {
    
  }
  &__functionTtl {
    width: 686/768*100vw;
    margin-left: 50/768*100vw;
    @include pc {
      width: 686/2*1px;
      margin-left: 50/2*1px;
    }
  }
  &__functionSubttl {
    position: relative;
    width: 660/768*100vw;
    color: #FFF;
    @include vw(34);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 30/768*100vw auto 0;
    padding: 10/768*100vw 0;
    background: #FF697C;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 3/768*100vw 3/768*100vw 0 0 #FF697C, 3/768*100vw 3/768*100vw 0 2px #000;
    @include pc {
      width: 660/2*1px;
      font-size: 34/2*1px;
      margin: 30/2*1px auto 0;
      padding: 10/2*1px 0;
      box-shadow: 3/2*1px 3/2*1px 0 0 #FF697C, 3/2*1px 3/2*1px 0 2px #000;
    }
    &--01 {
      margin-top: 30/768*100vw;
      @include pc {
        margin-top: 30/2*1px;
      }
    }
    &--02 {
      margin-top: 100/768*100vw;
      @include pc {
        margin-top: 100/2*1px;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: -130/768*100vw;
        left: 0;
        width: 691/768*100vw;
        height: 112/768*100vw;
        background: url(/assets/img/basic/treatment_01.webp);
        background-size: contain;
        background-repeat: repeat;
        background-position: center center;
        @include pc {
          top: -130/2*1px;
          width: 691/2*1px;
          height: 112/2*1px;
        }
      }
    }
    &--03 {
      margin-top: 100/768*100vw;
      @include pc {
        margin-top: 100/2*1px;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: -80/768*100vw;
        left: -30/768*100vw;
        width: 720/768*100vw;
        height: 454/768*100vw;
        background: url(/assets/img/basic/treatment_02.webp);
        background-size: contain;
        background-repeat: repeat;
        background-position: center center;
        @include pc {
          top: -80/2*1px;
          left: -30/2*1px;
          width: 720/2*1px;
          height: 454/2*1px;
        }
      }
    }
  }
  &__functionPopup {
    position: relative;
  }
  &__functionPopupImg {
    width: 474/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 474/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  &__functionPopupTatch {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &--01 {
      bottom: 160/768*100vw;
      left: 0;
      right: 0;
      width: 360/768*100vw;
      margin: 0 auto;
      @include pc {
        bottom: 160/2*1px;
        width: 360/2*1px;
      }
    }
    &--02 {
      bottom: 60/768*100vw;
      left: 0;
      right: 0;
      width: 360/768*100vw;
      margin: 0 auto;
      @include pc {
        bottom: 60/2*1px;
        width: 360/2*1px;
      }
    }
  }
  &__functionPopupTatchItem {
    cursor: pointer;
    animation: zoom-inOut-icon 1.5s infinite;
    &--01,&--02,&--03,&--04,&--05 {
      width: 60/768*100vw;
      @include pc {
        width: 60/2*1px;
      }
    }
    &--06,&--07,&--08,&--09,&--10 {
      width: 39/768*100vw;
      @include pc {
        width: 39/2*1px;
      }
    }
    @keyframes zoom-inOut-icon {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
    }
  }
  &__functionPopupList {
//    position: absolute;
//    top: 100/768*100vw;
  }
  &__functionPopupListItem {
    position: absolute;
    width: 344/768*100vw;
    padding: 30/768*100vw 35/768*100vw;
    background: #FFF;
    box-shadow: 10/768*100vw 10/768*100vw 0 0 rgba(0,0,0,0.1);
    opacity: 0;
    transition: all 0.3s;
    @include pc {
      width: 344/2*1px;
      padding: 30/2*1px 35/2*1px;
      box-shadow: 10/2*1px 10/2*1px 0 0 rgba(0,0,0,0.1);
    }
    &.active {
      opacity: 1.0;
    }
    &:nth-of-type(1) {
      top: 450/768*100vw;
      left: 20/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 450/2*1px;
        left: 20/2*1px;
      }
    }
    &:nth-of-type(2) {
      top: 450/768*100vw;
      left: 20/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 450/2*1px;
        left: 20/2*1px;
      }
    }
    &:nth-of-type(3) {
      top: 450 /768*100vw;
      right: 20/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 450 /2*1px;
        right: 20/2*1px;
      }
    }
    &:nth-of-type(4) {
      top: 450 /768*100vw;
      right: 20/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 450 /2*1px;
        right: 20/2*1px;
      }
    }
    &:nth-of-type(5) {
      top: 450 /768*100vw;
      right: 20/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 450 /2*1px;
        right: 20/2*1px;
      }
    }
    &:nth-of-type(6) {
      top: 500/768*100vw;
      left: 20/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 500/2*1px;
        left: 20/2*1px;
      }
    }
    &:nth-of-type(7) {
      top: 500/768*100vw;
      left: 20/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 500/2*1px;
        left: 20/2*1px;
      }
    }
    &:nth-of-type(8) {
      top: 400/768*100vw;
      left: 20/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 400/2*1px;
        left: 20/2*1px;
      }
    }
    &:nth-of-type(9) {
      top: 400/768*100vw;
      right: 20/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 400/2*1px;
        right: 20/2*1px;
      }
    }
    &:nth-of-type(10) {
      top: 500/768*100vw;
      right: 20/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 500/2*1px;
        right: 20/2*1px;
      }
    }
  }
  &__functionPopupListTtl {
    @include vw(28);
    font-weight: 900;
    line-height: 1.5;
    @include pc {
      font-size: 28/2*1px;
    }
    &.pink {
      color: #FE4E6A;
    }
    &.lightRed {
      color: #F64F2A;
    }
    &.red {
      color: #E70037;
    }
    &.green {
      color: #2BE2AD;
    }
    &.lightGreen {
      color: #2CE1AC;
    }
    &.purple {
      color: #9A27FF;
    }
    &.blue {
      color: #288CFA;
    }
    &.lightBlue {
      color: #2EADFF;
    }
    &.orange {
      color: #FF9A08;
    }
    &.yellow {
      color: #FFC300;
    }
  }
  &__functionPopupListTxt {
    @include vw(22);
    line-height: 1.5;
    margin-top: 10/768*100vw;
    @include pc {
      font-size: 22/2*1px;
      margin-top: 10/2*1px;
    }
  }
  &__functionSpeech {
    width: 649/768*100vw;
    @include vw(30);
    font-weight: 700;
    margin: 50/768*100vw auto 0;
    padding: 40/768*100vw 40/768*100vw 80/768*100vw;
    background: url(/assets/img/basic/basic_speech_bg.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    @include pc {
      width: 649/2*1px;
      font-size: 30/2*1px;
      margin: 50/2*1px auto 0;
      padding: 40/2*1px 40/2*1px 80/2*1px;
    }
  }
  &__functionSpeechImg {
    width: 474/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 474/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  &__functionMovie {
    position: relative;
    width: 474/768*100vw;
    margin: 50/768*100vw auto 0;
//    pointer-events: none;
    @include pc {
      width: 474/2*1px;
      margin: 50/2*1px auto 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 12px;
      left: 0;
      right: 0;
      width: 190/768*100vw;
      height: 30/768*100vw;
      margin: 0 auto;
      background: #000;
      border-radius: 0 0 30/768*100vw 30/768*100vw;
      z-index: 1;
      @include pc {
        top: 12/2*1px;
        width: 190/2*1px;
        height: 30/2*1px;
      }
    }
    video {
      position: relative;
      width: 100%;
      background: #000;
      border: 14px solid #000;
      border-radius: 40px;
      @include pc {
        border: 12px solid #000;
      }
    }
  }
  &__functionSpeechTxt {
    opacity: 0;
    width: 650/768*100vw;
    @include vw(22);
    font-weight: 500;
    margin: 50/768*100vw auto 0;
    padding: 30/768*100vw 35/768*100vw;
    background: #FFF;
    border-radius: 20px 20px 5px 20px;
    box-shadow: 10/768*100vw 10/768*100vw 0 0 rgba(0,0,0,0.1);
    transition: all 0.1s;
    @include pc {
      width: 650/2*1px;
      font-size: 22/2*1px;
      margin: 50/2*1px auto 0;
      padding: 30/2*1px 35/2*1px;
      border-radius: 20px 20px 5px 20px;
      box-shadow: 10/2*1px 10/2*1px 0 0 rgba(0,0,0,0.1);
    }
    span {
      @include vw(22);
      font-weight: 700;
      @include pc {
        font-size: 22/2*1px;
      }
    }
  }
  &__functionSlider {
    position: relative;
    width: 474/768*100vw;
    margin: 150/768*100vw auto 0;
    padding-bottom: 40/768*100vw;
    @include pc {
      width: 474/2*1px;
      margin: 150/2*1px auto 0;
      padding-bottom: 40/2*1px;
    }
    .swiper-slide {
      position: relative;
      width: 474/768*100vw !important;
      @include pc {
        width: 474/2*1px !important;
      }
      &:before {
        position: absolute;
        display: block;
        content: '';
        top: 12px;
        left: 0;
        right: 0;
        width: 190/768*100vw;
        height: 30/768*100vw;
        margin: 0 auto;
        background: #000;
        border-radius: 0 0 30/768*100vw 30/768*100vw;
        z-index: 1;
        @include pc {
          width: 190/2*1px;
          height: 30/2*1px;
        }
      }
      video {
        width: 100%;
        background: #000;
        border: 14px solid #000;
        border-radius: 40px;
      }
    }
    .swiper-button-prev {
      top: 50%;
      left: -90/768*100vw;
      width: 69/768*100vw;
      height:  69/768*100vw;
      margin: 0;
      background-image: url(/assets/img/basic/slider_prev.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%);
      @include pc {
        left: -90/2*1px;
        width: 69/2*1px;
        height:  69/2*1px;
      }
    }
    .swiper-button-next {
      top: 50%;
      right: -90/768*100vw;
      width: 69/768*100vw;
      height: 69/768*100vw;
      margin: 0;
      background-image: url(/assets/img/basic/slider_next.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      transform: translateY(-50%);
      @include pc {
        right: -90/2*1px;
        width: 69/2*1px;
        height: 69/2*1px;
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50/768*100vw;
      margin: 20/768*100vw auto 0;
      @include pc {
        width: 50/2*1px;
        margin: 20/2*1px auto 0;
      }
    }
    .swiper-pagination-bullet {
      width: 16/768*100vw;
      height: 16/768*100vw;
      background: #000;
      opacity: 1.0;
      @include pc {
        width: 16/2*1px;
        height: 16/2*1px;
      }
    }
    .swiper-pagination-bullet-active {
      background: #FE4E6A;
      border: 2px solid #000;
    }
  }
  &__functionSliderTxt {
    position: absolute;
    display: none;
    top: -110/768*100vw;
    left: 50%;
    width: 650/768*100vw;
    height: 60/768*100vw;
    color: #FFF;
    @include vw(30);
    font-weight: 700;
    line-height: 1.0;
    text-align: center;
    margin: 0 auto;
    padding: 14/768*100vw 0;
    background: #2E2F33;
    transform: translateX(-50%);
    @include pc {
      top: -110/2*1px;
      width: 650/2*1px;
      height: 60/2*1px;
      font-size: 30/2*1px;
      margin: 0 auto;
      padding: 14/2*1px 0;
    }
  }
  
  
  &__functionMore {
    position: relative;
    background: #FF697C;
    overflow: hidden;
    padding: 50/768*100vw 0 380/768*100vw;
    @include pc {
      padding: 50/2*1px 0 380/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -10/768*100vw;
      left: 0;
      right: 0;
      width: 583/768*100vw;
      height: 310/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/basic/functionMore_bottomImg.webp);
      background-repeat: repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        bottom: -10/2*1px;
        width: 583/2*1px;
        height: 310/2*1px;
      }
    }
  }
  &__functionMoreTtl {
    width: 670/768*100vw;
    margin-left: 50/768*100vw;
    @include pc {
      width: 670/2*1px;
      margin-left: 50/2*1px;
    }
  }
  &__functionMoreSlider {
    margin: 50/768*100vw 50/768*100vw 0;
    padding: 0 0 50/768*100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      margin: 50/2*1px 50/2*1px 0;
      padding: 0 0 50/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #2EADFF;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__functionMoreSliderInner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1600/768*100vw;
    @include pc {
      width: 1600/2*1px;
    }
  }
  &__functionMoreSliderItem {
    position: relative;
    width: 375/768*100vw;
    padding: 30/768*100vw 40/768*100vw 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    @include pc {
      width: 375/2*1px;
      padding: 30/2*1px 40/2*1px 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 10/768*100vw;
      right: 10/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 10/2*1px;
        right: 10/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_green.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_purple.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_pink.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A08 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A08 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_orange.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__functionMoreSliderNum {
    @include vw(24);
    font-weight: 900;
    @include pc {
      font-size: 24/2*1px;
    }
    &.purple {
      color: #9C58DD;
    }
    &.pink {
      color: #FE4E6A;
    }
    &.orange {
      color: #FF9A08;
    }
    &.green {
      color: #2BE2AD;
    }
  }
  &__functionMoreSliderTtl {
    min-height: 140/768*100vw;
    margin-top: 50/768*100vw;
    @include pc {
      min-height: 140/2*1px;
      margin-top: 50/2*1px;
    }
    span {
      @include vw(38);
      line-height: 1.8;
      font-weight: 900;
      @include pc {
        font-size: 38/2*1px;
      }
    }
    &.green {
      span {
        border-bottom: 1px dashed #2BE2AD;
      }
    }
    &.purple {
      span {
        border-bottom: 1px dashed #9C58DD;
      }
    }
    &.pink {
      span {
        border-bottom: 1px dashed #FE4E6A;
      }
    }
    &.orange {
      span {
        border-bottom: 1px dashed #FF9A08;
      }
    }
  }
  &__functionMoreSliderImg {
    width: 232/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 232/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  &__functionMoreImg {
    width: 583/768*100vw;
    margin: 80/768*100vw auto -5/768*100vw;
    @include pc {
      width: 583/2*1px;
      margin: 80/2*1px auto -5/2*1px;
    }
  }
  
  
  &__functionModal {
    position: relative;
    width: 630/768*100vw;
    padding: 50/768*100vw 0;
    background: url(/assets/img/top/dotted_bg_gray01.webp)#FFF;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    border: 2px solid #000;
    border-radius: 5px;
    @include pc {
      width: 630/2*1px;
      padding: 50/2*1px 0;
      background-size: 34/2*1px 34/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &.purple {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #9C58DD, 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.pink {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #FE4E6A , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.red {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F64F2A , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F64F2A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.orange {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F86635 , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F86635 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.yellow {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F9EB20 , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F9EB20 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.green {
      &:before {
        box-shadow: 10/768*100vw 10/768*100vw 0px 0px #2BE2AD , 10/768*100vw 10/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
  }
  &__functionModalTtl {
    margin: 0 auto;
    &--01 {
      width: 481/768*100vw;
      @include pc {
        width: 481/2*1px;
      }
    }
    &--02 {
      width: 509/768*100vw;
      @include pc {
        width: 509/2*1px;
      }
    }
    &--03 {
      width: 451/768*100vw;
      @include pc {
        width: 451/2*1px;
      }
    }
    &--04 {
      width: 506/768*100vw;
      @include pc {
        width: 506/2*1px;
      }
    }
  }
  &__functionModalSubttl {
    position: relative;
    width: 515/768*100vw;
    color: #FFF;
    @include vw(30);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 30/768*100vw auto 0;
    padding: 15/768*100vw 0;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 3/768*100vw 3/768*100vw 0px 0px #000;
    @include pc {
      width: 515/2*1px;
      font-size: 30/2*1px;
      margin: 30/2*1px auto 0;
      padding: 15/2*1px 0;
      box-shadow: 3/2*1px 3/2*1px 0px 0px #000;
    }
    &.green {
      background: #29E2AD;
    }
    &.purple {
      background: #9C58DD;
    }
    &.pink {
      background: #FE4E6A;
    }
    &.orange {
      background: #F86635;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
    }
    &.modal01-treatment01 {
      &:before {
        top: -90/768*100vw;
        left: -35/768*100vw;
        width: 568/768*100vw;
        height: 336/768*100vw;
        background-image: url(/assets/img/basic/modal01/treatment_01.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: -90/2*1px;
          left: -35/2*1px;
          width: 568/2*1px;
          height: 336/2*1px;
        }
      }
    }
    &.modal01-treatment02 {
      &:before {
        top: 90/768*100vw;
        left: -20/768*100vw;
        width: 548/768*100vw;
        height: 229/768*100vw;
        background-image: url(/assets/img/basic/modal01/treatment_02.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: 90/2*1px;
          left: -20/2*1px;
          width: 548/2*1px;
          height: 229/2*1px;
        }
      }
    }
    &.modal02-treatment01 {
      &:before {
        top: -80/768*100vw;
        left: -40/768*100vw;
        width: 555/768*100vw;
        height: 311/768*100vw;
        background-image: url(/assets/img/basic/modal02/treatment_01.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: -80/2*1px;
          left: -40/2*1px;
          width: 555/2*1px;
          height: 311/2*1px;
        }
      }
    }
    &.modal02-treatment02 {
      &:before {
        top: -90/768*100vw;
        left: -30/768*100vw;
        width: 570/768*100vw;
        height: 323/768*100vw;
        background-image: url(/assets/img/basic/modal02/treatment_02.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: -90/2*1px;
          left: -30/2*1px;
          width: 570/2*1px;
          height: 323/2*1px;
        }
      }
    }
    &.modal03-treatment01 {
//      &:before {
//        top: -70/768*100vw;
//        left: -10/768*100vw;
//        width: 564/768*100vw;
//        height: 304/768*100vw;
//        background-image: url(/assets/img/basic/modal03/treatment_01.webp);
//        background-size: 100% 100%;
//        background-position: center;
//        background-repeat: no-repeat;
//        @include pc {
//          top: -70/2*1px;
//          left: -10/2*1px;
//          width: 564/2*1px;
//          height: 304/2*1px;
//        }
//      }
    }
    &.modal03-treatment02 {
      &:before {
        top: -90/768*100vw;
        left: -30/768*100vw;
        width: 577/768*100vw;
        height: 326/768*100vw;
        background-image: url(/assets/img/basic/modal03/treatment_02.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: -90/2*1px;
          left: -30/2*1px;
          width: 577/2*1px;
          height: 326/2*1px;
        }
      }
    }
    &.modal04-treatment01 {
      &:before {
        top: -90/768*100vw;
        left: -40/768*100vw;
        width: 576/768*100vw;
        height: 312/768*100vw;
        background-image: url(/assets/img/basic/modal04/treatment_01.webp);
        background-size: 100% 100%;
        background-position: center;
        background-repeat: no-repeat;
        @include pc {
          top: -90/2*1px;
          left: -40/2*1px;
          width: 576/2*1px;
          height: 312/2*1px;
        }
      }
    }
    &.mt100 {
      margin-top: 100/768*100vw;
      @include pc {
        margin-top: 100/2*1px;
      }
    }
  }
  &__functionModalBoxTxt {
    width: 515/768*100vw;
    @include vw(26);
    font-weight: 500;
    margin: 40/768*100vw auto 0;
    padding: 35/768*100vw;
    @include pc {
      width: 515/2*1px;
      font-size: 26/2*1px;
      margin: 40/2*1px auto 0;
      padding: 35/2*1px;
    }
    &.green {
      background: #C3FFED;
    }
    &.purple {
      background: #EBD6FF;
    }
    &.pink {
      background: #FFD1D9;
    }
    &.orange {
      background: #FFD3CB;
    }
    a {
      text-decoration: underline;
    }
  }
  &__functionModalTxt {
    @include vw(30);
    font-weight: normal;
    text-align: center;
    margin-top: 30/768*100vw;
    @include pc {
      font-size: 30/2*1px;
      margin-top: 30/2*1px;
    }
    &.bold {
      font-weight: 700;
    }
  }
  &__functionModalBlock {
    display: flex;
    justify-content: space-between;
    width: 512/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 512/2*1px;
    }
  }
  &__functionModalBlockInner {
    width: 249/768*100vw;
    margin: 25/768*100vw auto 0;
    @include pc {
      width: 249/2*1px;
      margin: 25/2*1px auto 0;
    }
  }
  &__functionModalBlockTxt {
    @include vw(26);
    line-height: 1.5;
    text-align: center;
    margin-top: 15/768*100vw;
    @include pc {
      font-size: 26/2*1px;
      margin-top: 15/2*1px;
    }
  }
  &__functionModalDisplay {
    position: relative;
    margin: 60/768*100vw auto 0;
    @include pc {
      margin: 60/2*1px auto 0;
    }
  }
  &__functionModalDisplayImg {
    width: 316/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 316/2*1px;
    }
  }
  &__functionModalDisplayMovie {
    position: relative;
    width: 316/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 316/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 15/768*100vw;
      left: 0;
      right: 0;
      width: 130/768*100vw;
      height: 15/768*100vw;
      margin: 0 auto;
      background: #000;
      border-radius: 0 0 30/768*100vw 30/768*100vw;
      z-index: 1;
      @include pc {
        top: 15/2*1px;
        width: 130/2*1px;
        height: 15/2*1px;
      }
    }
    video {
      width: 100%;
      background: #000;
      border: 16/768*100vw solid #000;
      border-radius: 40/768*100vw;
      @include pc {
        border: 16/2*1px solid #000;
        border-radius: 40/2*1px;
      }
    }
  }
  &__functionModalDisplayPopup {
    position: absolute;
    width: 280/768*100vw;
    @include vw(22);
    font-weight: 500;
    line-height: 1.5;
    padding: 20/768*100vw 25/768*100vw;
    background: #FFF;
    box-shadow: 10/768*100vw 10/768*100vw 0 0 rgba(0,0,0,0.1);
    @include pc {
      width: 280/2*1px;
      font-size: 22/2*1px;
      font-weight: 500;
      padding: 20/2*1px 25/2*1px;
      box-shadow: 10/2*1px 10/2*1px 0 0 rgba(0,0,0,0.1);
    }
    span {
      @include vw(18);
      @include pc {
        font-size: 18/2*1px;
      }
    }
    &--topLeft {
      top: -30/768*100vw;
      left: -50/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 10/2*1px;
        left: -50/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &--topRight {
      top: -30/768*100vw;
      right: -50/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 10/2*1px;
        right: -50/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &--topCenter {
      top: 50%;
      right: -50/768*100vw;
      border-radius: 20px 20px 5px 20px;
      transform: translateY(-50%);
      @include pc {
        right: -50/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &--bottomLeft {
      bottom: 0/768*100vw;
      left: -50/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        bottom: 30/2*1px;
        left: -50/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &--bottomRight {
      bottom: 0/768*100vw;
      right: -50/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        bottom: 30/2*1px;
        right: -50/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &--sliderTopRight {
      display: none;
      top: -30/768*100vw;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      z-index: 1;
      @include pc {
        top: 10/2*1px;
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &--sliderTopLeft {
      display: none;
      top: -30/768*100vw;
      left: -200/768*100vw;
      border-radius: 20px 20px 20px 5px;
      z-index: 1;
      @include pc {
        top: 10/2*1px;
        left: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &--sliderTopRight {
      display: none;
      top: -30/768*100vw;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      z-index: 1;
      @include pc {
        top: 10/2*1px;
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &--sliderBottomLeft {
      display: none;
      bottom: 30/768*100vw;
      left: -200/768*100vw;
      border-radius: 20px 20px 20px 5px;
      z-index: 1;
      @include pc {
        bottom: 30/2*1px;
        left: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &--sliderBottomRight {
      display: none;
      bottom: 30/768*100vw;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      z-index: 1;
      @include pc {
        bottom: 30/2*1px;
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &.purple {
      border: 1px solid #9C58DD;
    }
    &.pink {
      border: 1px solid #FE4E6A;
    }
  }
  &__functionModalSlider {
    position: relative;
    width: 316/768*100vw;
    margin: 60/768*100vw auto 0;
    padding-bottom: 40/768*100vw;
    @include pc {
      width: 316/2*1px;
      margin: 60/2*1px auto 0;
      padding-bottom: 40/2*1px;
    }
    .swiper-slide {
      width: 316/768*100vw !important;
      @include pc {
        width: 316/2*1px !important;
      }
    }
    .swiper-button-prev {
      top: 50%;
      left: -90/768*100vw;
      width: 69/768*100vw;
      height:  69/768*100vw;
      margin: 0;
      transform: translateY(-50%);
      @include pc {
        left: -90/2*1px;
        width: 69/2*1px;
        height:  69/2*1px;
      }
      &.green {
        background-image: url(/assets/img/basic/modal01/slider_prev.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.purple {
        background-image: url(/assets/img/basic/modal02/slider_prev.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.orange {
        background-image: url(/assets/img/basic/modal04/slider_prev.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .swiper-button-next {
      top: 50%;
      right: -90/768*100vw;
      width: 69/768*100vw;
      height:  69/768*100vw;
      margin: 0;
      transform: translateY(-50%);
      @include pc {
        right: -90/2*1px;
        width: 69/2*1px;
        height:  69/2*1px;
      }
      &.green {
        background-image: url(/assets/img/basic/modal01/slider_next.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.purple {
        background-image: url(/assets/img/basic/modal02/slider_next.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
      &.orange {
        background-image: url(/assets/img/basic/modal04/slider_next.webp);
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .swiper-pagination {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 50/768*100vw;
      margin: 20/768*100vw auto 0;
      @include pc {
        width: 50/2*1px;
        margin: 20/2*1px auto 0;
      }
      &.green {
        .swiper-pagination-bullet-active {
          background: #2BE2AD;
        }
      }
      &.purple {
        .swiper-pagination-bullet-active {
          background: #9C58DD;
        }
      }
      &.orange {
        .swiper-pagination-bullet-active {
          background: #F86635;
        }
      }
    }
    .swiper-pagination-bullet {
      width: 16/768*100vw;
      height: 16/768*100vw;
      background: #000;
      opacity: 1.0;
      @include pc {
        width: 16/2*1px;
        height: 16/2*1px;
      }
    }
    .swiper-pagination-bullet-active {
      border: 2px solid #000;
    }
  }
  &__functionModalSliderMovie {
    position: relative;
    width: 316/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 316/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 15/768*100vw;
      left: 0;
      right: 0;
      width: 130/768*100vw;
      height: 15/768*100vw;
      margin: 0 auto;
      background: #000;
      border-radius: 0 0 30/768*100vw 30/768*100vw;
      z-index: 1;
      @include pc {
        top: 15/2*1px;
        width: 130/2*1px;
        height: 15/2*1px;
      }
    }
    video {
      width: 100%;
      background: #000;
      border: 16/768*100vw solid #000;
      border-radius: 40/768*100vw;
      @include pc {
        border: 16/2*1px solid #000;
        border-radius: 40/2*1px;
      }
    }
  }
  &__functionModalClose {
    position: absolute;
    top: 20/768*100vw;
    right: 20/768*100vw;
    width: 56/768*100vw;
    height: 56/768*100vw;
    transform: rotate(45deg);
    transition: all 0s;
    cursor: pointer;
    @include pc {
      top: 20/2*1px;
      right: 20/2*1px;
      width: 56/2*1px;
      height: 56/2*1px;
    }
  }
  &__functionModalCharacter {
    position: relative;
    width: 525/768*100vw;
    margin: 50/768*100vw auto 0;
    padding-top: 445/768*100vw;
    @include pc {
      width: 525/2*1px;
      margin: 50/2*1px auto 0;
      padding-top: 445/2*1px;
    }
  }
  &__functionModalCharacterImg {
    width: 256/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 256/2*1px;
    }
  }
  &__functionModalCharacterTxt {
    position: absolute;
    &--01 {
      width: 265/768*100vw;
      top: 0;
      left: -10/768*100vw;
      @include pc {
        width: 265/2*1px;
        left: -10/2*1px;
      }
    }
    &--02 {
      width: 265/768*100vw;
      top: 40/768*100vw;
      right: -10/768*100vw;
      @include pc {
        width: 265/2*1px;
        top: 40/2*1px;
        right: -10/2*1px;
      }
    }
    &--03 {
      width: 265/768*100vw;
      top: 190/768*100vw;
      left: -10/768*100vw;
      @include pc {
        width: 265/2*1px;
        top: 190/2*1px;
        left: -10/2*1px;
      }
    }
  }
  &__functionModalComingsoon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 580/768*100vw;
//    height: 320/768*100vw;
    @include vw(52);
    font-weight: 700;
    margin: 30/768*100vw auto 0;
    background: #FFF;
//    border: 2px solid #000;
    border-radius: 5px;
    @include pc {
      width: 580/2*1px;
//      height: 320/2*1px;
      font-size: 52/2*1px;
      margin: 30/2*1px auto 0;
    }
    &:before {
      position: absolute;
//      display: block;
      display: none;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
     &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A08 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A08 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
  &__functionModalYt {
    margin: 30/768*100vw auto 0;
    @include pc {
      margin: 30/2*1px auto 0;
    }
    iframe {
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }
  &__functionModalRule {
    margin-top: 80/768*100vw;
    @include pc {
      margin-top: 80/2*1px;
    }
  }
  &__functionModalRuleItem {
    position: relative;
    width: 510/768*100vw;
    @include vw(26);
    margin: 0 auto;
    padding: 35/768*100vw 40/768*100vw;
    background: #FAFAFA;
    border: 2px solid #000;
    border-radius: 30px;
    @include pc {
      width: 510/2*1px;
      font-size: 26/2*1px;
      margin: 0 auto;
      padding: 35/2*1px 40/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: -35/768*100vw;
      left: -30/768*100vw;
      width: 85/768*100vw;
      height: 83/768*100vw;
      @include pc {
        top: -35/2*1px;
        left: -30/2*1px;
        width: 85/2*1px;
        height: 83/2*1px;
      }
    }
    &:nth-of-type(n+2) {
      margin-top: 60/768*100vw;
      @include pc {
        margin-top: 60/2*1px
      }
    }
    &:nth-of-type(1):before {
      background: url(/assets/img/basic/modal03/num01.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:nth-of-type(2):before {
      background: url(/assets/img/basic/modal03/num02.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:nth-of-type(3):before {
      background: url(/assets/img/basic/modal03/num03.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:nth-of-type(4):before {
      background: url(/assets/img/basic/modal03/num04.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:nth-of-type(5):before {
      background: url(/assets/img/basic/modal03/num05.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    &:nth-of-type(6):before {
      background: url(/assets/img/basic/modal03/num06.webp);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}