.m-modal {
  position: relative;
  width: 630/768*100vw;
  margin: 0 auto;
  padding: 60/768*100vw 0 100/768*100vw;
  background-image: url(/assets/img/top/dotted_bg_white.webp);
  background-repeat: repeat;
  background-position: center center;
  background-size: 34/768*100vw 34/768*100vw;
  border: 2px solid #000;
  border-radius: 5px;
  @include pc {
    width: 630/2*1px;
    padding: 60/2*1px 0 100/2*1px;
    background-size: 34/2*1px 34/2*1px;
  }
  &:before {
    position: absolute;
    display: block;
    content: '';
    top: 2px;
    right: -2px;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
  }
  &.pink {
    background-color: #FFDCD5;
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #FC2D7B , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #FC2D7B , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.yellow {
    background-color: #F8E2C2;
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #E89D33 , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #E89D33 , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.black {
    background-color: #EBEBEB;
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #606060 , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #606060 , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.blue {
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #52A4FF , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #52A4FF , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.purple {
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #9C58DD, 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.green {
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #2BE2AD , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.orange {
    &:before {
      box-shadow: 10/768*100vw 10/768*100vw 0px 0px #F86635 , 10/768*100vw 10/768*100vw 0px 2px #000;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #F86635 , 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &.bg-white {
    background-color: #FFF;
    background-image: url(/assets/img/top/dotted_bg_gray01.webp);
  }
  
  
  &__ttl {
    width: 630/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 630/2*1px;
    }
  }
  
  
  &__subttl {
    position: relative;
    width: 512/768*100vw;
    color: #FFF;
    @include vw(30);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 30/768*100vw auto 0;
    padding: 15/768*100vw 0;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 3/768*100vw 3/768*100vw 0px 0px #000;
    @include pc {
      width: 512/2*1px;
      font-size: 30/2*1px;
      margin: 30/2*1px auto 0;
      padding: 15/2*1px 0;
      box-shadow: 3/2*1px 3/2*1px 0px 0px #000;
    }
    &.pink-grad {
      background: rgb(255,114,87);
      background: linear-gradient(90deg, rgba(255,114,87,1) 0%, rgba(252,45,123,1) 100%);
    }
    &.yellow-grad {
      background: rgb(232,157,51);
      background: linear-gradient(90deg, rgba(232,157,51,1) 0%, rgba(238,200,111,1) 100%);
    }
    &.black-grad {
      background: #2E2F33;
      background: linear-gradient(to right, #2E2F33 0%, #3D3D40 25%, #606060 50%, #3D3D40 75%, #2E2F33 100%);
    }
    &.blue {
      background: #52A4FF;
    }
    &.purple {
      background: #9C58DD;
    }
    &.pink {
      background: #FE4E6A;
    }
    &.green {
      background: #29E2AD;
    }
    &.orange {
      background: #F86635;
    }
  }
  
  
  &__lineTxt {
    @include vw(30);
    font-weight: 700;
    line-height: 1.8;
    text-align: center;
    margin-top: 30/768*100vw;
    @include pc {
      font-size: 30/2*1px;
      margin-top: 30/2*1px;
    }
    &.pink {
      span {
        border-bottom: 2px dashed #FC2D7B;
      }
    }
    &.yellow {
      span {
        border-bottom: 2px dashed #E89D33;
      }
    }
    &.black {
      span {
        border-bottom: 2px dashed #424242;
      }
    }
  }
  
  
  &__box {
    width: 512/768*100vw;
    margin: 40/768*100vw auto 0;
    padding: 40/768*100vw;
    background: rgba(255,255,255,0.7);
    @include pc {
      width: 512/2*1px;
      margin: 40/2*1px auto 0;
      padding: 40/2*1px;
    }
    &.blue {
      background: #CBE9FF;
    }
    &.purple {
      background: #EBD6FF;
    }
    &.green {
      background: #C3FFED;
    }
    &.pink {
      background: #FFD1D9;
    }
    &.orange {
      background: #FFD3CB;
    }
    
  }
  &__boxTxt {
    @include vw(26);
    font-weight: 500;
    line-height: 1.8;
    @include pc {
      font-size: 26/2*1px;
    }
    a {
      color: #9C58DD;
      text-decoration: underline;
    }
  }
  
  
  &__illust {
    width: 630/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 630/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  
  
  &__video {
    position: relative;
    width: 316/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 316/2*1px;
      margin: 50/2*1px auto 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 15/768*100vw;
      left: 0;
      right: 0;
      width: 130/768*100vw;
      height: 15/768*100vw;
      margin: 0 auto;
      background: #000;
      border-radius: 0 0 30/768*100vw 30/768*100vw;
      z-index: 1;
      @include pc {
        top: 15/2*1px;
        width: 130/2*1px;
        height: 15/2*1px;
      }
    }
    video {
      width: 100%;
      background: #000;
      border: 16/768*100vw solid #000;
      border-radius: 40/768*100vw;
      @include pc {
        border: 16/2*1px solid #000;
        border-radius: 40/2*1px;
      }
    }
  }
  
  
  &__screen {
    position: relative;
    width: 316/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 316/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  &__screenTxt {
    position: absolute;
    width: 280/768*100vw;
    @include vw(22);
    font-weight: 500;
    line-height: 1.5;
    padding: 20/768*100vw 25/768*100vw;
    background: #FFF;
    box-shadow: 10/768*100vw 10/768*100vw 0 0 rgba(0,0,0,0.1);
    @include pc {
      width: 280/2*1px;
      font-size: 22/2*1px;
      padding: 20/2*1px 25/2*1px;
      box-shadow: 10/2*1px 10/2*1px 0 0 rgba(0,0,0,0.1);
    }
    &.topLeft {
      top: 50/768*100vw;
      left: -200/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        top: 50/2*1px;
        left: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &.topRight {
      top: 50/768*100vw;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        top: 50/2*1px;
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &.topCenter {
      top: 50%;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      transform: translateY(-50%);
      @include pc {
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
    &.bottomLeft {
      bottom: 50/768*100vw;
      left: -200/768*100vw;
      border-radius: 20px 20px 20px 5px;
      @include pc {
        bottom: 50/2*1px;
        left: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 20/2*1px 5/2*1px;
      }
    }
    &.bottomRight {
      bottom: 50/768*100vw;
      right: -200/768*100vw;
      border-radius: 20px 20px 5px 20px;
      @include pc {
        bottom: 50/2*1px;
        right: -200/2*1px;
        border-radius: 20/2*1px 20/2*1px 5/2*1px 20/2*1px;
      }
    }
  }
  
  &__movie {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 500/768*100vw;
    height: 320/768*100vw;
    margin: 30/768*100vw auto 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    @include pc {
      width: 500/2*1px;
      height: 320/2*1px;
      margin: 30/2*1px auto 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    &.yt {
      width: 580/768*100vw;
      border: none;
      @include pc {
        width: 580/2*1px;
      }
      &:before {
        display: none;
      }
      iframe {
        width: 100%;
        aspect-ratio: 16/9;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FC2D7B , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FC2D7B , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.yellow {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #EEC86F , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #EEC86F , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.black {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #606060 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #606060 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.blue {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #52A4FF , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #52A4FF , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #F86635, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F86635, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
    }
    iframe {
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
  &__movieTxt {
    @include vw(52);
    font-weight: 700;
    @include pc {
      font-size: 52/2*1px;
    }
  }
  
  
  &__txt {
    width: 510/768*100vw;
    @include vw(26);
    font-weight: 500;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 510/2*1px;
      font-size: 26/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  
  
  &__notes {
    width: 510/768*100vw;
    @include vw(22);
    margin: 30/768*100vw auto 0;
    @include pc {
      width: 510/2*1px;
      font-size: 22/2*1px;
      margin: 30/2*1px auto 0;
    }
  }
  
  
  &__content {
    position: relative;
    width: 500/768*100vw;
    margin: 40/768*100vw auto 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      width: 500/2*1px;
      margin-top: 40/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5px 5px 0 -1px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5px 5px 0 -1px #000;
      }
    }
  }
  &__contentInner {
    padding: 30/768*100vw 20/768*100vw;
    @include pc {
      padding: 30/2*1px 20/2*1px;
    }
    &.pd {
      padding: 30/768*100vw 40/768*100vw;
      @include pc {
        padding: 30/2*1px 40/2*1px;;
      }
    }
  }
  &__contentArea {
    position: relative;
    width: 100%;
    padding-left: 130/768*100vw;
    @include pc {
      padding-left: 130/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 50%;
      left: 0;
      width: 118/768*100vw;
      height: 143/768*100vw;
      transform: translateY(-50%);
      @include pc {
        width: 118/2*1px;
        height: 143/2*1px;
      }
    }
    &--01 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon01.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--02 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon02.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--03 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon03.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--04 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon04.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--05 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon05.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &--06 {
      &:before {
        background: url(/assets/img/safety/slide-modal01/icon06.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__contentTtl {
    position: relative;
    @include vw(30);
    font-size: 700;
    line-height: 1.5;
    @include pc {
      font-size: 30/2*1px;
    }

  }
  &__contentSubttl {
    @include vw(24);
    margin-top: 10/768*100vw;
    @include pc {
      font-size: 24/2*1px;
      margin-top: 10/2*1px;
    }
  }
  &__contentTxt {
    @include vw(26);
    font-weight: 500;
    line-height: 1.8;
    padding: 30/768*100vw 40/768*100vw;
    background: #F3F3F3;
    border-top: 2px solid #000;
    border-radius: 0 0 5px 5px;
    @include pc {
      font-size: 26/2*1px;
      padding: 30/2*1px 40/2*1px;
    }
    .purple {
      color: #9C58DD;
      text-decoration: underline;
    }
  }
  
  
  &__talk {
    width: 512/768*100vw;
    @include vw(26);
    font-weight: 500;
    line-height: 1.8;
    margin: 30/768*100vw auto 0;
    padding: 40/768*100vw;
    background: #FAFAFA;
    border-radius: 10px 10px 3px 10px;
    box-shadow: 10/768*100vw 10/768*100vw 0 0 rgba(0,0,0,0.1);
    @include pc {
      width: 512/2*1px;
      font-size: 26/2*1px;
      margin: 30/2*1px auto 0;
      padding: 40/2*1px;
      box-shadow: 10/2*1px 10/2*1px 0 0 rgba(0,0,0,0.1);
    }
  }
  
  
  &__block {
    position: relative;
    width: 500/768*100vw;
    margin: 50/768*100vw auto 0;
    padding: 50/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      width: 500/2*1px;
      margin-top: 50/2*1px;
      padding: 50/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5px 5px 0 -1px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5px 5px 0 -1px #000;
      }
    }
  }
  &__blockTtl {
    @include vw(38);
    font-weight: 900;
    &.pink {
      color: #FE4E6A;
    }
    &.orange {
      color: #F86635;
    }
    &.purple {
      color: #9C58DD;
    }
    @include pc {
      font-size: 38/2*1px;
    }
  }
  &__blockTxt {
    @include vw(30);
    font-weight: 700;
    margin-top: 10/768*100vw;
    @include pc {
      font-size: 30/2*1px;
      margin-top: 10/2*1px;
    }
    &:nth-of-type(n+2) {
      margin-top: 1em;
    }
    &.ti {
      text-indent: -1em;
      padding-left: 1em;
    }
    a {
      &.pink {
        color: #FE4E6A;
        text-decoration: underline;
      }
      &.purple {
        color: #9C58DD;
        text-decoration: underline;
      }
    }
    span {
      cursor: pointer;
      &.pink {
        color: #FE4E6A;
        text-decoration: underline;
      }
      &.purple {
        color: #9C58DD;
        text-decoration: underline;
      }
    }
  }
  
  
  &__attention {
    margin-top: 80/768*100vw;
    @include pc {
      margin-top: 80/2*1px;
    }
  }
  &__attentionItem {
    position: relative;
    width: 510/768*100vw;
    margin: 0 auto;
    padding: 40/768*100vw;
    background: #FAFAFA;
    border: 2px solid #000;
    border-radius: 20px;
    @include pc {
      width: 510/2*1px;
      padding: 40/2*1px;
    }
    &:nth-of-type(n+2) {
      margin-top: 60/768*100vw;
      @include pc {
        margin-top: 60/2*1px;
      }
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: -40/768*100vw;
      left: -20/768*100vw;
      width: 80/768*100vw;
      height: 80/768*100vw;
      background: url(/assets/img/safety/slide-modal03/attention_icon.webp);
      background-size: contain;
      background-repeat: no-repeat;
      @include pc {
        top: -40/2*1px;
        left: -20/2*1px;
        width: 80/2*1px;
        height: 80/2*1px;
      }
    }
  }
  &__attentionTxt {
    @include vw(26);
    @include pc {
      font-size: 26/2*1px;
    }
  }
  
  
  &__close {
    position: absolute;
    top: 20/768*100vw;
    right: 20/768*100vw;
    width: 56/768*100vw;
    height: 56/768*100vw;
    transform: rotate(-45deg);
    transition: all 0s;
    cursor: pointer;
    @include pc {
      top: 20/2*1px;
      right: 20/2*1px;
      width: 56/2*1px;
      height: 56/2*1px;
    }
  }
  
  
  .mt10 {
    margin-top: 10/768*100vw;
    @include pc {
      margin-top: 10/2*1px;
    }
  }
  .mt20 {
    margin-top: 20/768*100vw;
    @include pc {
      margin-top: 20/2*1px;
    }
  }
  .mt30 {
    margin-top: 30/768*100vw;
    @include pc {
      margin-top: 30/2*1px;
    }
  }
  .mt40 {
    margin-top: 40/768*100vw;
    @include pc {
      margin-top: 40/2*1px;
    }
  }
  .mt50 {
    margin-top: 50/768*100vw;
    @include pc {
      margin-top: 50/2*1px;
    }
  }
  .mt60 {
    margin-top: 60/768*100vw;
    @include pc {
      margin-top: 60/2*1px;
    }
  }
  .mt70 {
    margin-top: 70/768*100vw;
    @include pc {
      margin-top: 70/2*1px;
    }
  }
  .mt80 {
    margin-top: 80/768*100vw;
    @include pc {
      margin-top: 80/2*1px;
    }
  }
  .mt90 {
    margin-top: 90/768*100vw;
    @include pc {
      margin-top: 90/2*1px;
    }
  }
  .mt100 {
    margin-top: 100/768*100vw;
    @include pc {
      margin-top: 100/2*1px;
    }
  }

}