.m-function {
  &__scroll {
    margin: 40/768*100vw 50/768*100vw 0;
    padding: 0 0 50/768*100vw;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @include pc {
      margin: 50/2*1px 50/2*1px 0;
      padding: 0 0 50/2*1px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &.orange {
      .simplebar-scrollbar::before {
        background: #FF9A08 !important;
      }
    }
    &.pink {
      .simplebar-scrollbar::before {
        background: #FF697C !important;
      }
    }
    .simplebar-content {
      padding-top: 0 !important;
    }
    .simplebar-scrollbar::before {
      top: 0;
      left: 0;
      width: 100%;
      background: #FF3854;
      border-radius: 5px;
      height: 5px !important;
    }
    .simplebar-scrollbar.simplebar-visible::before {
      opacity: 1;
    }
    .simplebar-track {
      width: 99%;
      height: 5px !important;
      margin: 0 auto;
      background: #FAFAFA;
      border-radius: 5px;
    }
  }
  &__scrollInner {
    width: 1610/768*100vw;
    @include pc {
      width: 1610/2*1px;
    }
    .safety {
      width: 2430/768*100vw;
      padding: 0 10/768*100vw 0 0;
      @include pc {
        width: 2430/2*1px;
      }
    }
  }
  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1600/768*100vw;
    @include pc {
      width: 1600/2*1px;
    }
    .safety {
      width: 2300/768*100vw;
      @include pc {
        width: 2300/2*1px;
      }
    }
  }
  &__contentItem {
    position: relative;
    width: 375/768*100vw;
    padding: 30/768*100vw 40/768*100vw 0;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    @include pc {
      width: 375/2*1px;
      padding: 30/2*1px 40/2*1px 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 5px;
      z-index: -1;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 10/768*100vw;
      right: 10/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 10/2*1px;
        right: 10/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_green.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.blue {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #52A4FF , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #52A4FF , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_blue.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_purple.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_pink.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.yellow {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #E89D33 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #E89D33 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_yellow_grad.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #F86635 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #F86635 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_orange.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__contentNum {
    @include vw(24);
    font-weight: 900;
    @include pc {
      font-size: 24/2*1px;
    }
    &.green {
      color: #2BE2AD;
    }
    &.blue {
      color: #52A4FF;
    }
    &.purple {
      color: #9C58DD;
    }
    &.pink {
      color: #FE4E6A;
    }
    &.yellow {
      color: #E89D33;
    }
    &.orange {
      color: #F86635;
    }
  }
  &__contentTtl {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    min-height: 210/768*100vw;
    margin-top: 10/768*100vw;
    @include pc {
      min-height: 210/2*1px;
      margin-top: 10/2*1px;
    }
    span {
      @include vw(38);
      line-height: 1.8;
      font-weight: 900;
      @include pc {
        font-size: 38/2*1px;
      }
    }
    &.green {
      span {
        border-bottom: 1px dashed #2BE2AD;
      }
    }
    &.blue {
      span {
        border-bottom: 1px dashed #52A4FF;
      }
    }
    &.purple {
      span {
        border-bottom: 1px dashed #9C58DD;
      }
    }
    &.pink {
      span {
        border-bottom: 1px dashed #FE4E6A;
      }
    }
    &.yellow {
      span {
        border-bottom: 1px dashed #FF9A08;
      }
    }
    &.orange {
      span {
        border-bottom: 1px dashed #F86635;
      }
    }
  }
  &__contentImg {
    width: 232/768*100vw;
    margin: 20/768*100vw auto 0;
    @include pc {
      width: 232/2*1px;
      margin: 20/2*1px auto 0;
    }
  }
//  &__contentImg {
//    width: 583/768*100vw;
//    margin: 80/768*100vw auto -5/768*100vw;
//    @include pc {
//      width: 583/2*1px;
//      margin: 80/2*1px auto -5/2*1px;
//    }
//  }
}