.p-safety {
  &__inner {
    position: relative;
    padding: 20/768*100vw 0 0;
    background: url(/assets/img/top/dotted_bg_white.webp)#52A4FF;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 20/2*1px 0 0;
      background-size: 34/2*1px 34/2*1px;
    }
  }
  &__effort {
    padding: 20/768*100vw 0 160/768*100vw;
    @include pc {
      padding: 20/2*1px 0 160/2*1px;
    }
  }
  &__effortTtl {
    width: 700/768*100vw;
    margin: 0 auto;
    @include pc {
      width: 700/2*1px;
    }
  }
  &__effortSubttl {
    position: relative;
    width: 650/768*100vw;
    color: #FFF;
    @include vw(36);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 60/768*100vw auto 0;
    padding: 15/768*100vw 0;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 2/768*100vw 4/768*100vw 0px 0px #FF9A08 , 2/768*100vw 4/768*100vw 0px 2px #000;
    @include pc {
      width: 650/2*1px;
      font-size: 36/2*1px;
      margin: 60/2*1px auto 0;
      padding: 15/2*1px 0;
      box-shadow: 2/2*1px 4/2*1px 0px 0px #FF9A08 , 2/2*1px 4/2*1px 0px 2px #000;
    }
    &.orange {
      background: #FF9A08;
    }
  }
  &__effortBox {
    position: relative;
    width: 650/768*100vw;
    background: #FFF;
    margin: 50/768*100vw auto 0;
    padding: 80/768*100vw 50/768*100vw;
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 5px 5px 0px -1px #000;
    @include pc {
      width: 650/2*1px;
      margin: 50/2*1px auto 0;
      padding: 80/2*1px 50/2*1px;
    }
    &:nth-of-type(n+2) {
      margin-top: 30/768*100vw;
      @include pc {
        margin-top: 30/2*1px;
      }
    }
    &:before,&:after {
      position: absolute;
      display: block;
      content: '';
      top: -85/768*100vw;
      width: 45/768*100vw;
      height: 130/768*100vw;
      z-index: 1;
      @include pc {
        top: -85/2*1px;
        width: 45/2*1px;
        height: 130/2*1px;
      }
    }
    &:before {
      left: 40/768*100vw;
      background: url(/assets/img/safety/safety_icon.webp);
      background-size: contain;
      background-repeat: no-repeat;
      @include pc {
        left: 40/2*1px;
      }
    }
    &:after {
      right: 40/768*100vw;
      background: url(/assets/img/safety/safety_icon.webp);
      background-size: contain;
      background-repeat: no-repeat;
      @include pc {
        right: 40/2*1px;
      }
    }
    &.none {
      &:before,
      &:after {
        display: none;
      }
    }
  }
  &__effortBoxTxt {
    @include vw(30);
    font-weight: 500;
    @include pc {
      font-size: 30/2*1px;
    }
    span {
      color: #FF9A08;
      @include vw(32);
      font-weight: 500;
      @include pc {
        font-size: 32/2*1px;
      }
    }
  }
  &__effortBoxTtl {
    @include vw(40);
    font-weight: 700;
    @include pc {
      font-size: 40/2*1px;
    }
  }
  &__effortBoxList {
    margin-top: 50/768*100vw;
    @include pc {
      margin-top: 50/2*1px;
    }
  }
  &__effortBoxListItem {
    @include vw(30);
    font-weight: 500;
    @include pc {
      font-size: 30/2*1px;
    }
    span {
      color: #FF9A08;
      @include vw(32);
      @include pc {
        font-size: 32/2*1px;
      }
    }
    &:nth-of-type(n+2) {
      margin-top: 40/768*100vw;
      @include pc {
        margin-top: 40/2*1px;
      }
    }
  }
  
  
  &__screen {
    width: 472/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 472/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  
  &__function {
    position: relative;
    padding: 50/768*100vw 0 380/768*100vw;
    @include pc {
      padding: 50/2*1px 0 380/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -10/768*100vw;
      left: 0;
      right: 0;
      width: 583/768*100vw;
      height: 310/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/basic/functionMore_bottomImg.webp);
      background-repeat: repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        bottom: -10/2*1px;
        width: 583/2*1px;
        height: 310/2*1px;
      }
    }
  }
  &__functionTtl {
    width: 679/768*100vw;
    margin: 50/768*100vw auto 0;
    @include pc {
      width: 679/2*1px;
      margin: 50/2*1px auto 0;
    }
  }
  
  
  &__modalSubttl {
    &:before {
      position: absolute;
      display: block;
      content: '';
    }
    &--01 {
      &:before {
        top: -80/768*100vw;
        left: -50/768*100vw;
        width: 601/768*100vw;
        height: 257/768*100vw;
        background: url(/assets/img/safety/slide-modal01/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -80/2*1px;
          left: -50/2*1px;
          width: 601/2*1px;
          height: 257/2*1px;
        }
      }
    }
    &--02 {
      &:before {
        top: -90/768*100vw;
        left: -60/768*100vw;
        width: 610/768*100vw;
        height: 117/768*100vw;
        background: url(/assets/img/safety/slide-modal02/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -90/2*1px;
          left: -60/2*1px;
          width: 610/2*1px;
          height: 117/2*1px;
        }
      }
    }
    &--03 {
      &:before {
        top: -85/768*100vw;
        left: -50/768*100vw;
        width: 600/768*100vw;
        height: 229/768*100vw;
        background: url(/assets/img/safety/slide-modal03/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -85/2*1px;
          left: -50/2*1px;
          width: 600/2*1px;
          height: 229/2*1px;
        }
      }
    }
    &--05 {
      &:before {
        top: -90/768*100vw;
        left: -50/768*100vw;
        width: 559/768*100vw;
        height: 289/768*100vw;
        background: url(/assets/img/safety/slide-modal05/treatment.webp);
        background-repeat: no-repeat;
        background-size: contain;
        @include pc {
          top: -90/2*1px;
          left: -50/2*1px;
          width: 559/2*1px;
          height: 289/2*1px;
        }
      }
    }
  }
}