/**
 * initiallize
 * タグ自体のstyleを記述
 */

html {
  font-family: var(--font-family-basic);
  font-size: $base_fs * 1px;
  line-height: 1.6;
  color: var(--color_txt);
  word-wrap: break-word;
}


body {
  position: relative;
  width: 100%;
  height: 100%;
  -webkit-text-size-adjust: 100%;
  line-height: 1.6;
}

a {
  color: var(--color_lnk);
  text-decoration: var(--link_deco);
  transition: opacity 0.2s;
  &:hover {
    text-decoration: none;
    opacity: 0.8;
  }
}
img {
  max-width: 100%;
  vertical-align: bottom;
  @include sp {
    width: 100%;
  }
}

h2,h3,h4 {
  font-weight: 700;
}

strong {
  font-weight: bold;
}

input {
  padding: 0;
  background: none;
  border: none;
}
* {
  box-sizing: border-box;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  &::after, &::before {
    box-sizing: border-box;
  }
}
input[type=text], input[type=tel], input[type=password], input[type=email], input[type=search], input[type=url], input[type=datetime], input[type=date], input[type=month], input[type=week], input[type=time], input[type=datetime-local], input[type=number], select, textarea {
  padding: 6px;
  font-family: var(--font-family-basic);
  vertical-align: bottom;
  background-color: #fff;
  border: solid 1px #c9c9c9;
  &::-webkit-input-placeholder {
    color: var(--color_placeholder);
  }
  &:-ms-input-placeholder {
    color: var(--color_placeholder);
  }
  &::-moz-placeholder {
    color: var(--color_placeholder);
  }
  &:focus {
    background-color: #eef4fb;
  }
}

input[type=radio], input[type=checkbox] {
  margin: 0;
  vertical-align: -1px;
}

input[type="button"], input[type="submit"] {
  font-family: var(--font-family-basic);
  vertical-align: bottom;
  cursor: pointer;
  -webkit-appearance: none;
}

textarea {
  vertical-align: bottom;
  resize: vertical;
}

em {
  font-style: italic;
}

sup {
  font-size: 0.5em;
  vertical-align: super;
}

sub {
  font-size: 0.5em;
  vertical-align: sub;
}

blockquote {
  position: relative;
  padding: 1em 1em 1em 3em;
  background-color: #eeefff;
  border-left: 3px solid #666;
}
blockquote::before {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 600%;
  line-height: 1em;
  color: #999;
  content: "“";
}

::selection {
  background: #d4dcd6; /* Safari */
}
::-moz-selection {
  background: #d4dcd6; /* Firefox */
}
