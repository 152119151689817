.p-travelGuide {
  background: url(/assets/img/top/dotted_bg_gray01.webp);
  background-repeat: repeat;
  background-position: center center;
  background-size: 34/768*100vw 34/768*100vw;
  @include pc {
    background-size: 34/2*1px 34/2*1px;
  }
  &__lead {
    padding: 50/768*100vw 0 150/768*100vw;
    background: url(/assets/img/top/dotted_bg_gray01.webp);
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      background-size: 34/2*1px 34/2*1px;
      padding: 50/2*1px 0 150/2*1px;
    }
  }
  &__leadInner {
    position: relative;
    width: 750/768*100vw;
    height: 929/768*100vw;
    margin: 0 auto;
    background: url(/assets/img/travel-guide/lead.webp);
    background-size: cover;
    background-repeat: repeat;
    background-position: center center;
    @include pc {
      width: 750/2*1px;
      height: 929/2*1px;
    }
  }
  &__leadTxt {
    position: absolute;
    top: 300/768*100vw;
    left: 0;
    right: 0;
    @include vw(34);
    font-weight: 700;
    text-align: center;
    margin: 0 auto;
    @include pc {
      top: 300/2*1px;
      font-size: 34/2*1px;
    }
  }
  &__leadDetail {
    width: 650/768*100vw;
    margin: 80/768*100vw auto 0;
    padding: 40/768*100vw;
    background: #FAFAFA;
    border-radius: 30/768*100vw;
    border: 2px solid #2E2F33;
    @include pc {
      width: 650/2*1px;
      margin: 80/2*1px auto 0;
      padding: 40/2*1px;
      border-radius: 30/2*1px;
    }
  }
  &__leadDetailTxt {
    @include vw(26);
    @include pc {
      font-size: 26/2*1px;
    }
  }
  
  &__page {
    padding: 100/768*100vw 0 150/768*100vw;
    background: url(/assets/img/top/dotted_bg_gray02.webp)#FFF569;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 100/2*1px 0 150/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
  }
  &__pageTtl {
    width: 100%;
  }
  &__pageContent {
    position: relative;
    width: 650/768*100vw;
    margin: 30/768*100vw auto 0;
    background: #FF9A08;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      width: 650/2*1px;
      margin: 30/2*1px auto 0;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A08, 5/768*100vw 5/768*100vw 0px 2px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A08, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
  }
  &__pageContentTtl {
    color: #FFF;
    @include vw(40);
    line-height: 1.5;
    padding: 30/768*100vw 50/768*100vw;
    @include pc {
      font-size: 40/2*1px;
      padding: 30/2*1px 50/2*1px;
    }
  }
  &__pageContentTxt {
    @include vw(30);
    font-weight: 700;
    line-height: 1.8;
    padding: 40/768*100vw 50/768*100vw;
    background: #FFF;
    border-top: 2px solid #000;
    border-radius: 0 0 5px 5px;
    @include pc {
      font-size: 30/2*1px;
      padding: 40/2*1px 50/2*1px;
    }
  }
  &__pageAbout {
    position: relative;
    width: 650/768*100vw;
    margin: 70/768*100vw auto 0;
    padding: 70/768*100vw 50/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    z-index: 1;
    @include pc {
      width: 650/2*1px;
      margin: 70/2*1px auto 0;
      padding: 70/2*1px 50/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      box-shadow: 5/768*100vw 5/768*100vw 0px 0px #000, 5/768*100vw 5/768*100vw 0px 2px #000;
      border-radius: 5px;
      z-index: -1;
      @include pc {
        box-shadow: 5/2*1px 5/2*1px 0px 0px #000, 5/2*1px 5/2*1px 0px 2px #000;
      }
    }
    &.mt {
      margin-top: 40/768*100vw;
      @include pc {
        margin-top: 40/2*1px;
      }
    }
  }
  &__pageAboutIcon {
    position: absolute;
    top: -85/768*100vw;
    left: 0;
    width: 100%;
    height: 125/768*100vw;
    z-index: 1;
    @include pc {
      top: -85/2*1px;
      height: 125/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      left: 40/768*100vw;
      width: 45/768*100vw;
      height: 130/768*100vw;
      background: url(/assets/img/travel-guide/icon.webp);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        left: 40/2*1px;
        width: 45/2*1px;
        height: 130/2*1px;
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      top: 0;
      right: 40/768*100vw;
      width: 45/768*100vw;
      height: 130/768*100vw;
      background: url(/assets/img/travel-guide/icon.webp);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      @include pc {
        right: 40/2*1px;
        width: 45/2*1px;
        height: 130/2*1px;
      }
    }
  }
  &__pageAboutBlock {
    display: flex;
    align-items: center;
  }
  &__pageAboutBlockImg {
    width: 180/768*100vw;
    @include pc {
      width: 180/2*1px;
    }
  }
  &__pageAboutBlockName {
    line-height: 1.5;
    .kanji {
      @include vw(40);
      font-weight: 900;
      @include pc {
        font-size: 40/2*1px;
      }
    }
    .kana {
      @include vw(26);
      font-weight: 500;
      @include pc {
        font-size: 26/2*1px;
      }
    }
  }
  &__pageAboutTtl {
    color: #2E2F33;
    @include vw(40);
    font-weight: 900;
    line-height: 1.5;
    @include pc {
      font-size: 40/2*1px;
    }
  }
  &__pageAboutSubttl {
    color: #FF9A08;
    @include vw(32);
    font-weight: 700;
    line-height: 1.5;
    margin-top: 30/768*100vw;
    @include pc {
      font-size: 32/2*1px;
      margin-top: 30/2*1px;
    }
  }
  &__pageAboutTxt {
    @include vw(26);
    font-weight: 700;
    line-height: 1.8;
    margin-top: 25/768*100vw;
    border-radius: 0 0 5px 5px;
    word-break: break-all;
    @include pc {
      font-size: 26/2*1px;
      margin-top: 25/2*1px;
    }
    a {
      color: #FF9A08;
    }
  }
  &__pageTrip {
    margin-top: 70/768*100vw;
    cursor: pointer;
    @include pc {
      margin-top: 70/2*1px;
      transition: all 0.3s;
      &:hover {
        opacity: 0.75;
      }
    }
  }
  
  &__carefull {
    position: relative;
    padding: 100/768*100vw 0 310/768*100vw;
    background: url(/assets/img/top/dotted_bg_gray01.webp)#FF9A08;
    background-repeat: repeat;
    background-position: center center;
    background-size: 34/768*100vw 34/768*100vw;
    @include pc {
      padding: 100/2*1px 0 310/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      bottom: -30/768*100vw;
      left: 0;
      right: 0;
      width: 100%;
      height: 270/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/travel-guide/carefull_img01.webp);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      z-index: 2;
      @include pc {
        bottom: -30/2*1px;
        height: 270/2*1px;
      }
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: -30/768*100vw;
      left: 0;
      right: 0;
      width: 100%;
      height: 270/768*100vw;
      margin: 0 auto;
      background: url(/assets/img/travel-guide/carefull_img02.webp);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      z-index: 4;
      @include pc {
        bottom: -30/2*1px;
        height: 270/2*1px;
      }
    }
  }
  &__carefullTtl {
    position: relative;
    width: 650/768*100vw;
    color: #FFF;
    @include vw(36);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 30/768*100vw auto 0;
    padding: 10/768*100vw 0;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 3/768*100vw 3/768*100vw 0 0 #000, 3/768*100vw 3/768*100vw 0 2px #000;
    @include pc {
      width: 650/2*1px;
      font-size: 36/2*1px;
      margin: 30/2*1px auto 0;
      padding: 10/2*1px 0;
      box-shadow: 3/2*1px 3/2*1px 0 0 #FF697C, 3/2*1px 3/2*1px 0 2px #000;
    }
    &--purple {
      background: #9C58DD;
      box-shadow: 3/768*100vw 3/768*100vw 0 0 #9C58DD, 3/768*100vw 3/768*100vw 0 2px #000;
      @include pc {
        box-shadow: 3/2*1px 3/2*1px 0 0 #9C58DD, 3/2*1px 3/2*1px 0 2px #000;
      }
    }
    &--pink {
      margin: 120/768*100vw auto 0;
      background: #FF697C;
      box-shadow: 3/768*100vw 3/768*100vw 0 0 #FF697C, 3/768*100vw 3/768*100vw 0 2px #000;
      @include pc {
        margin: 120/2*1px auto 0;
        box-shadow: 3/2*1px 3/2*1px 0 0 #FF697C, 3/2*1px 3/2*1px 0 2px #000;
      }
    }
  }
  &__carefullItem {
    position: relative;
    width: 650/768*100vw;
    margin: 25/768*100vw auto 0;
    padding: 30/768*100vw 40/768*100vw 60/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    cursor: pointer;
    @include pc {
      width: 650/2*1px;
      margin: 25/2*1px auto 0;
      padding: 30/2*1px 40/2*1px 60/2*1px;
    }
    &:nth-of-type(1),
    &:nth-of-type(5) {
      margin: 50/768*100vw auto 0;
      @include pc {
        margin: 50/2*1px auto 0;
      }
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      z-index: 0;
    }
    &:after {
      position: absolute;
      display: block;
      content: '';
      bottom: 10/768*100vw;
      right: 10/768*100vw;
      width: 42/768*100vw;
      height: 42/768*100vw;
      @include pc {
        bottom: 10/2*1px;
        right: 10/2*1px;
        width: 42/2*1px;
        height: 42/2*1px;
      }
    }
    &.green {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #2BE2AD , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #2BE2AD , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_green.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.purple {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #9C58DD, 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #9C58DD, 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_purple.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.pink {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FE4E6A , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FE4E6A , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_pink.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    &.orange {
      &:before {
        box-shadow: 5/768*100vw 5/768*100vw 0px 0px #FF9A08 , 5/768*100vw 5/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 5/2*1px 5/2*1px 0px 0px #FF9A08 , 5/2*1px 5/2*1px 0px 2px #000;
        }
      }
      &:after {
        background: url(/assets/img/common/btn_orange.webp);
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  &__carefullItemNum {
    @include vw(24);
    font-weight: 900;
    @include pc {
      font-size: 24/2*1px;
    }
    &.purple {
      color: #9C58DD;
    }
    &.pink {
      color: #FE4E6A;
    }
    &.orange {
      color: #FF9A08;
    }
    &.green {
      color: #2BE2AD;
    }
  }
  &__carefullItemTtl {
    margin-top: 20/768*100vw;
    @include pc {
      margin-top: 20/2*1px;
    }
    span {
      @include vw(38);
      line-height: 1.8;
      font-weight: 900;
      @include pc {
        font-size: 38/2*1px;
      }
    }
    &.green {
      span {
        border-bottom: 1px dashed #2BE2AD;
      }
    }
    &.purple {
      span {
        border-bottom: 1px dashed #9C58DD;
      }
    }
    &.pink {
      span {
        border-bottom: 1px dashed #FE4E6A;
      }
    }
    &.orange {
      span {
        border-bottom: 1px dashed #FF9A08;
      }
    }
  }
  
  //modal-css
  &__modal {
    position: relative;
    width: 630/768*100vw;
    padding: 60/768*100vw 60/768*100vw 80/768*100vw;
    background: #FFF;
    border: 2px solid #000;
    border-radius: 5px;
    @include pc {
      width: 630/2*1px;
      padding: 60/2*1px 60/2*1px 80/2*1px;
      background-size: 34/2*1px 34/2*1px;
    }
    &:before {
      position: absolute;
      display: block;
      content: '';
      top: 2px;
      right: -2px;
      width: 100%;
      height: 100%;
      border-radius: 3px;
      z-index: -1;
    }
    &.blue {
      background: #E5F1FF;
      &:before {
        box-shadow: 12/768*100vw 12/768*100vw 0px 0px #52A4FF, 12/768*100vw 12/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 12/2*1px 12/2*1px 0px 0px #52A4FF, 12/2*1px 12/2*1px 0px 2px #000;
        }
      }
    }
    &.purple {
      &:before {
        box-shadow: 12/768*100vw 12/768*100vw 0px 0px #9C58DD, 12/768*100vw 12/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 12/2*1px 12/2*1px 0px 0px #9C58DD, 12/2*1px 12/2*1px 0px 2px #000;
        }
      }
    }
    &.pink {
      &:before {
        box-shadow: 12/768*100vw 12/768*100vw 0px 0px #FE4E6A , 12/768*100vw 12/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 12/2*1px 12/2*1px 0px 0px #FE4E6A , 12/2*1px 12/2*1px 0px 2px #000;
        }
      }
    }
    &.orange {
      &:before {
        box-shadow: 12/768*100vw 12/768*100vw 0px 0px #FF9A08 , 12/768*100vw 12/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 12/2*1px 12/2*1px 0px 0px #FF9A08 , 12/2*1px 12/2*1px 0px 2px #000;
        }
      }
    }
    &.green {
      &:before {
        box-shadow: 12/768*100vw 12/768*100vw 0px 0px #2BE2AD , 12/768*100vw 12/768*100vw 0px 2px #000;
        @include pc {
          box-shadow: 12/2*1px 12/2*1px 0px 0px #2BE2AD , 12/2*1px 12/2*1px 0px 2px #000;
        }
      }
    }
  }
  &__modalTtl {
    @include vw(48);
    font-weight: 900;
    @include pc {
      font-size: 48/2*1px;
    }
  }
  &__modalTxt {
    @include vw(30);
    margin-top: 30/768*100vw;
    @include pc {
      font-size: 30/2*1px;
      margin-top: 30/2*1px;
    }
  }
  &__modalSubttl {
    position: relative;
    width: 100%;
    color: #FFF;
    @include vw(26);
    font-weight: 900;
    line-height: 1.5;
    text-align: center;
    margin: 60/768*100vw auto 0;
    padding: 10/768*100vw 0;
    border: 2px solid #000;
    border-radius: 50px;
    box-shadow: 2/768*100vw 2/768*100vw 0 0 #000, 2/768*100vw 2/768*100vw 0 0 #000;
    @include pc {
      font-size: 26/2*1px;
      margin: 60/2*1px auto 0;
      padding: 10/2*1px 0;
      box-shadow: 2/2*1px 2/2*1px 0 0 #000, 2/2*1px 2/2*1px 0 0 #000;
    }
    &.blue {
      background: #52A4FF;
    }
    &.pink {
      background: #FE4E6A;
    }
    &.purple {
      background: #9C58DD;
    }
    &.green {
      background: #2BE2AD;
    }
    &.orange {
      background: #FF9A08;
    }
  }
  &__modalImg {
    margin: 50/768*100vw auto 0;
    @include pc {
      margin: 50/2*1px auto 0;
    }
    &.trip {
      width: 274/768*100vw;
      @include pc {
        width: 274/2*1px;
      }
    }
    &.feature01 {
      width: 513/768*100vw;
      @include pc {
        width: 513/2*1px;
      }
    }
    &.feature02 {
      width: 464/768*100vw;
      @include pc {
        width: 464/2*1px;
      }
    }
    &.feature03 {
      width: 505/768*100vw;
      @include pc {
        width: 505/2*1px;
      }
    }
    &.feature04 {
      width: 498/768*100vw;
      @include pc {
        width: 498/2*1px;
      }
    }
    &.feature05 {
      width: 352/768*100vw;
      @include pc {
        width: 352/2*1px;
      }
    }
    &.feature06 {
      width: 349/768*100vw;
      @include pc {
        width: 349/2*1px;
      }
    }
    &.feature07 {
      width: 372/768*100vw;
      @include pc {
        width: 372/2*1px;
      }
    }
    &.feature08 {
      width: 245/768*100vw;
      @include pc {
        width: 245/2*1px;
      }
    }
  }
  &__modalDetail {
    padding: 30/768*100vw 40/768*100vw;
    background: #FAFAFA;
    border: 2px solid #000;
    border-radius: 15/768*100vw;
    margin-top: 30/768*100vw;
    @include pc {
      padding: 30/2*1px 40/2*1px;
      border-radius: 15px;
      margin-top: 30/2*1px;
    }
  }
  &__modalDetailTxt {
    @include vw(26);
    @include pc {
      font-size: 26/2*1px;
    }
  }
  &__modalDetailNotes {
    @include vw(18);
    margin-top: 10/768*100vw;
    @include pc {
      font-size: 18/2*1px;
      margin-top: 10/2*1px;
    }
  }
  &__modalClose {
    position: absolute;
    bottom: 20/768*100vw;
    right: 20/768*100vw;
    width: 56/768*100vw;
    height: 56/768*100vw;
    transform: rotate(45deg);
    transition: all 0s;
    cursor: pointer;
    @include pc {
      bottom: 20/2*1px;
      right: 20/2*1px;
      width: 56/2*1px;
      height: 56/2*1px;
    }
  }
  
}